import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Login = _resolveComponent("Login")!
  const _component_Signup = _resolveComponent("Signup")!
  const _component_notification = _resolveComponent("notification")!
  const _component_LightHeader = _resolveComponent("LightHeader")!
  const _component_TheHeader = _resolveComponent("TheHeader")!
  const _component_TheSidenav = _resolveComponent("TheSidenav")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_LayoutNewFooter = _resolveComponent("LayoutNewFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Login),
    _createVNode(_component_Signup),
    _createVNode(_component_notification, {
      class: _normalizeClass({ ar: _ctx.language === 'ar' })
    }, null, 8, ["class"]),
    _createVNode(_component_LightHeader, {
      class: _normalizeClass({ ar: _ctx.language === 'ar' })
    }, null, 8, ["class"]),
    _createVNode(_component_TheHeader, {
      class: _normalizeClass({ ar: _ctx.language === 'ar' }),
      onSidenavToggle: _cache[0] || (_cache[0] = ($event: any) => (_ctx.displayNav = !_ctx.displayNav))
    }, null, 8, ["class"]),
    _createVNode(_component_TheSidenav, {
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.displayNav= false)),
      show: _ctx.displayNav
    }, null, 8, ["show"]),
    _createElementVNode("main", {
      class: _normalizeClass(["main-body", { ar: _ctx.language === 'ar' }])
    }, [
      _createVNode(_component_router_view, {
        class: _normalizeClass({ ar: _ctx.language === 'ar' })
      }, {
        default: _withCtx((slotProps) => [
          _createVNode(_Transition, {
            name: "route",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(slotProps.Component), {
                class: _normalizeClass({ ar: _ctx.language === 'ar' })
              }, null, 8, ["class"]))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }, 8, ["class"])
    ], 2),
    _createVNode(_component_LayoutNewFooter, {
      class: _normalizeClass({ ar: _ctx.language === 'ar' })
    }, null, 8, ["class"])
  ], 64))
}