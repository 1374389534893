<template>
  <div v-if="checkStorage">
    <MDBModal
        v-if="firstModal"
        tabindex="-1"
        style="display: block"
        :modelValue="showModal"
        :keyboard="false"
        :focus="true"
        staticBackdrop
    >
      <MDBModalBody>
        <div class="shipping-container">
          <div class="shipping-container">
            <p class="main-title text-capitalize">
              {{ $t("modal.shippingTitle") }}
            </p>
            <div class="modal-flex">
              <button class="shipping-button" @click="jordan()">
                <img
                    src="@/assets/images/jordan.svg"
                    width="70"
                    height="70"
                    alt="jordan"
                />
                <p class="shipping-hint">{{ $t("modal.jordan") }}</p>
              </button>
              <button class="shipping-button" @click="submitForm()">
                <img
                    src="@/assets/images/international.svg"
                    width="70"
                    height="70"
                    alt="international"
                />
                <p class="shipping-hint">{{ $t("modal.international") }}</p>
              </button>
            </div>
          </div>
        </div>
      </MDBModalBody>
    </MDBModal>
  </div>
  <div v-else-if="checkStorageAvailable && show">
    <MDBModal
        v-if="firstModal"
        tabindex="-1"
        style="display: block"
        :modelValue="showModal"
        :keyboard="false"
        :focus="true"
        @hide="toggleModal(0)"
    >
      <MDBModalHeader @click="closeModal"></MDBModalHeader>

      <MDBModalBody>
        <div class="shipping-container">
          <p class="main-title text-capitalize">
            {{ $t("modal.shippingTitle") }}
          </p>
          <div class="modal-flex">
            <button class="shipping-button" @click="nextModal(false)">
              <img
                  src="@/assets/images/jordan.svg"
                  width="70"
                  height="70"
                  alt="jordan"
              />
              <p class="shipping-hint">{{ $t("modal.jordan") }}</p>
            </button>
            <button class="shipping-button" @click="nextModal(true)">
              <img
                  src="@/assets/images/international.svg"
                  width="70"
                  height="70"
                  alt="international"
              />
              <p class="shipping-hint">{{ $t("modal.international") }}</p>
            </button>
          </div>
        </div>
      </MDBModalBody>
    </MDBModal>
    <MDBModal
        v-else
        tabindex="-1"
        style="display: block"
        :modelValue="showModal"
        :keyboard="false"
        :focus="true"
    >
      <MDBModalBody>
        <div class="shipping-container">
          <p class="main-title mb-5">{{ $t("modal.sure") }}</p>
          <p class="sub-title-second" v-if="!isInternational">{{ $t("modal.sureText") }}</p>
          <div class="button-container">
            <BaseButton @click="submitForm()">{{
                $t("modal.Continue")
              }}
            </BaseButton>
            <BaseButton @click="previousModal()">{{
                $t("modal.cancel")
              }}
            </BaseButton>
          </div>
        </div>
      </MDBModalBody>
    </MDBModal>
  </div>
</template>
<script>
import {defineComponent} from "vue";
import {mapGetters, mapActions} from "vuex";
import {MDBModal, MDBModalBody} from "mdb-vue-ui-kit";

export default defineComponent({
  name: "CountryModal",
  computed: {
    ...mapGetters({
      showModal: "countryModal/showModal",
      checkStorage: "countryModal/checkSession",
      checkStorageAvailable: "countryModal/checkStorage",
      show: "countryModal/show",
    }),
    isInternational() {
      return localStorage.international === 'true';
    }
  },
  components: {
    MDBModal,
    MDBModalBody,
  },
  data() {
    return {
      check: 0,
      firstModal: true,
      international: true,
    };
  },
  methods: {
    ...mapActions({
      toggleModal: "countryModal/toggleModal",
    }),
    jordan() {
      localStorage.international = false;
      location.reload();
    },
    closeModal() {
      this.toggleModal(0);
    },
    nextModal(value) {
      this.international = value;
      if (this.international) {
        localStorage.currency = "USD";
      } else {
        localStorage.currency = "JOD";
      }
      this.firstModal = false;
    },
    previousModal() {
      this.firstModal = true;
    },
    submitForm() {
      localStorage.international = this.international;
      location.reload();
    },
  },
});
</script>

<style lang="scss" scoped>
.modal-body {
  padding: 50px;
  @media screen and (max-width: 525px) {
    padding: 40px 10px;
  }
}

.modal-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  flex-direction: row;
  @media screen and (max-width: 525px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}

p {
  margin: auto;
}

.shipping-button {
  display: block;
  border: 2px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  background: white;
  width: 240px;
  height: 185px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: tint-color(#c4c4c4, 80%);
  }

  @media screen and (max-width: 525px) {
    width: 120px;
    height: 120px;
    margin: 0;
    img {
      width: 35px;
      height: 35px;
    }
  }
}

.shipping-hint {
  margin-top: 20px;
  color: #003c73;
  text-align: center;
  font-size: 18px;
  margin-bottom: 0;
  @media screen and (max-width: 525px) {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .main-title {
    font-size: calc(30px - 10px) !important;
    color: #003c73;
  }
}

@media (max-width: 820px) {
  .main-title {
    font-size: 16px !important;
  }

  .sub-title {
    font-size: 13px !important;
  }

  .sub-title-second {
    font-size: 13px !important;
  }

  .shipping-button {
    font-size: 14px !important;
  }
}

.sub-title {
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #a7843b;
  width: 90%;
  margin-top: 26px;
  margin-bottom: 40px;
}

.sub-title-second {
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #a7843b;
  width: 100%;
  margin-top: 26px;
  margin-bottom: 20px;
}

.main-title {
  font-size: calc(30px - 5px);
  line-height: 37px;
  color: #003c73;
  text-align: center;
}

.shipping-button-app {
  border: none;
  height: 40px;
  border-radius: 30px;
  background-color: #003c73;
  color: white;
  width: calc(50% - 20px);
  @media screen and(max-width: 525px) {
    margin-bottom: 0px;
  }
}

.app-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  @media screen and(max-width: 525px) {
    flex-direction: column;
    margin-bottom: 15px;
  }
}

.modal-dialog {
  max-width: 660px !important;
}

.button-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 10px;

  @media screen and (max-width: 525px) {
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}
</style>
