import {getAllCategories} from "@/services/category.service";


export default {
    async setAllCategories(context: any) {
        const categories = await getAllCategories()
        context.commit('clearParams')
        await context.commit('setCategories', categories);
        return true
    },
    addCategoryIdToParams(context: any, payload: any): boolean {
        const allCategories: any = []
        const allChildren: any = []
        categories(context.state.categories)
        checkAllChildren(payload)
        if (!context.state.categoriesIdList.includes(payload)) {
            checkId(payload)
        } else {
            checkremove(payload)
        }
        function checkremove(id: number): boolean {
            context.commit('removeFromCategoriesList', id)
            return true;
        }
        function checkId(id: number): boolean {
            context.commit('setCategoriesId', id)
            return true
        }
        function categories(array: any) {
            for (let i = 0; i < array.length; i++) {
                const category = {
                    id: array[i].id,
                    parent: array[i].parentId,
                    has_parent: !!array[i].parentId
                }
                allCategories.push(category)
                if (array[i].categories.length > 0) {
                    categories(array[i].categories)
                }
            }
        }
        function checkAllChildren(id: number) {
            for (let i = 0; i < allCategories.length; i++) {
                if (allCategories[i].parent === id || allCategories[i].id === id) {
                    if (!allChildren.includes(allCategories[i].parent) && allCategories[i].parent !== null) {
                        allChildren.push(allCategories[i].parent)
                    }
                    if (!allChildren.includes(allCategories[i].id) && allCategories[i].id !== null) {
                        allChildren.push(allCategories[i].id)
                        if (allCategories[i].parent !== null && allCategories[i].has_parent) {
                            checkAllChildren(allCategories[i].id)
                        }
                    }


                }
            }
        }

        return true

    },
    clearList(context: any) {
        context.commit('clearParams');
        return true
    },
    findParents(context: any, id: number) {
        const allChildren: any = []
        const allCategories: any = []
        categories(context.state.categories)
        checkAllChildren(id)

        function parent(id: number) {
            const data = allCategories.find((item: any) => {
                if (+item.id === +id) {
                    return item
                }
            })
            if (data.has_parent) {
                const dataParent = allCategories.find((item: any) => {
                    if (+item.id === +data.parent) {
                        return item
                    }
                })
                const ParentOfParents = allCategories.find((item: any) => {
                    if (+item.id === +dataParent.parent) {
                        return item
                    }
                })
                const  ParentOfParentsOfParents = allCategories.find((item: any) => {
                    if (+item.id === +ParentOfParents?.parent) {
                        return item
                    }
                }
                )
                if (ParentOfParentsOfParents) {
                    context.commit('setParentCategory', ParentOfParentsOfParents)
                    return ParentOfParentsOfParents;
                }else if (ParentOfParents) {
                    context.commit('setParentCategory', ParentOfParents)
                    return ParentOfParents
                } else if (dataParent) {
                    context.commit('setParentCategory', dataParent)
                    return dataParent
                } else {
                    context.commit('setParentCategory', data)
                    return data
                }
            } else {
                context.commit('setParentCategory', data)
                return data
            }

            return true;
        }

        parent(id)

        function checkAllChildren(id: number) {
            for (let i = 0; i < allCategories.length; i++) {
                if (allCategories[i].parent === id || allCategories[i].id === id) {
                    if (!allChildren.includes(allCategories[i].parent) && allCategories[i].parent !== null) {
                        allChildren.push(allCategories[i].parent)
                    }
                    if (!allChildren.includes(allCategories[i].id) && allCategories[i].id !== null) {
                        allChildren.push(allCategories[i].id)
                        if (allCategories[i].parent !== null && allCategories[i].has_parent) {
                            checkAllChildren(allCategories[i].id)
                        }
                    }


                }
            }
        }

        function categories(array: any) {
            for (let i = 0; i < array.length; i++) {
                const category = {
                    id: array[i].id,
                    parent: array[i].parentId,
                    has_parent: !!array[i].parentId
                }
                allCategories.push(category)
                if (array[i].categories.length > 0) {
                    categories(array[i].categories)
                }
            }
        }

    },
    clearParent(context: any) {
        context.commit('clearParentCategory');
    }
}




