import axios from "@/axios";
import categoryInterface from "@/interfaces/category.interface";
import store from "@/store";

export default async function addToCart(data: {
  productVariationId: number;
  quantity: number;
}): Promise<any> {
  const path = `/carts/items`;
  try {
    const response = await axios.post(`${path}`, data);
    await store.dispatch("cart/getCartItems");
    return response;
  } catch (e: any) {
    const i = e;
    return e;
  }
}

export async function editCartItem(itemId: number, data: { quantity: number }) {
  const path = `/carts/items/` + itemId;
  const response = await axios.patch(`${path}`, data);
  await store.dispatch("cart/getCartItems");
}

export async function removeFromCart(itemId: number) {
  const path = `carts/items/${itemId}`;
  const response = await axios.delete(`${path}`);
  await store.dispatch("cart/getCartItems");
}

export async function addToCartCard(data: {
  productId: number;
  quantity: number;
}): Promise<any> {
  const path = `/carts/items/` + data.productId;
  try {
    const response = await axios.post(`${path}`, {
      quantity: data.quantity,
    });
    await store.dispatch("cart/getCartItems");
    return response;
  } catch (e: any) {
    const i = e;
    return e;
  }
}
