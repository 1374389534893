<template>
  <section class="mb-5">
    <section class="error-area ptb-60">
      <div class="container">
        <div class="error-content">
          <img src="@/assets/images/404.png" alt="error">

          <h3>Page Not Found</h3>
          <p>The <span  class="text-lowercase">page you are looking for might have been removed had its name changed or is temporarily
            unavailable.</span></p>

          <router-link :to="{path:'/'+local}" class="btn btn-primary">Go to Home</router-link>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: ['error'],
  layout: 'error-layout',
  computed: {
    ...mapGetters({
      local: 'localization/locale'
    })
  }
}
</script>

<style lang="scss" scoped>
$paragraph-color: #666666;
$black-color: #222222;

.page-title-area {
  background-color: #f8f8f8;
  padding: {
    top: 12px;
    bottom: 12px;
  }

  ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;

    li {
      display: inline-block;
      margin-right: 15px;
      position: relative;
      color: $paragraph-color;
      font-size: 13px;

      a {
        color: $paragraph-color;
        font-size: 13px;

        &:hover, &:focus {
          color: $black-color;
        }
      }

      &::before {
        content: '/';
        position: absolute;
        display: inline-block;
        left: -10px;
        top: 0;
      }

      &:first-child {
        &::before {
          display: none;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.error-content {
  text-align: center;
  margin: 0 auto;
  max-width: 520px;

  h3 {
    font-size: 30px;
    margin: {
      top: 30px;
      bottom: 15px;
    }
  }

  p {
    margin-bottom: 25px;
  }
}
</style>


