import {createStore} from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import localization from "./modules/localization";
import auth from "./modules/auth";
import notification from "@/store/modules/notification";
import cart from "./modules/cart";
import wishlist from "./modules/wishlist";
import countryModal from "./modules/country-modal";
import categories from "@/store/modules/categories";
import home from "@/store/modules/home";
import main from "@/store/modules/main";
import zalatimo from "@/store/modules/zalatimo";

const ls = new SecureLS({
    isCompression: false,
});

export default createStore({
    state: {},

    getters: {},

    mutations: {},

    actions: {},

    modules: {
        localization,
        auth,
        notification,
        countryModal,
        cart,
        categories,
        wishlist,
        home,
        main,
        zalatimo
    },

    plugins: [
        createPersistedState({
            paths: [
                "localization",
                "auth.authenticated",
                "auth.user",
                "auth.idToken",
            ],
            storage: {
                getItem: (key) => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key),
            },
        }),
    ],
});
