import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state() {
    return {
      swap : 0,
        show : true
    };
  },
   mutations,
   actions,
   getters
};