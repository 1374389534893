
import {defineComponent} from "vue";
import {mapGetters} from "vuex";
import axios from "@/axios";

export default defineComponent({
  emits: ["close"],
  props: ["productId", "productVariationId"],
  data() {
    return {
      phoneNumber: null as string | null,
      notes: null as string | null,
    };
  },
  computed: {
    ...mapGetters({
      local: "localization/locale",
      user: "auth/user",
    }),
  },
  methods: {
    async SendQuote() {
      if (this.productId) {
        try {
          await axios.post("quote", {
            phoneNumber: this.phoneNumber,
            notes: this.notes,
            productId: this.productId,
          });
          this.$notifySuccess("Your Quote Received Successfully");
          this.phoneNumber = "";
          this.notes = "";
          this.$emit("close");
        } catch (e) {
          this.$notifyError("Something Went Wrong");
        }
      } else {
        try {
          await axios.post("quote", {
            phoneNumber: this.phoneNumber,
            notes: this.notes,
            productVariationId: this.productVariationId,
          });
          this.$notifySuccess("Your Quote Received Successfully");
          this.phoneNumber = "";
          this.notes = "";
          this.$emit("close");
        } catch (e) {
          this.$notifyError("Something Went Wrong");
        }
      }
    },
  },
  created() {
    if (
        this.user.phoneNumber !== "anonymous" &&
        this.user.phoneNumber !== null &&
        this.user.phoneNumber !== ""
    ) {
      this.phoneNumber = this.user.phoneNumber;
    } else {
      this.phoneNumber = "";
    }
  },
});
