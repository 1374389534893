export default {
    namespaced: true,

    state: {
        locale: process.env.VUE_APP_I18N_LOCALE,
        availableLocales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE,
        fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE
    },

    getters: {
        locale(state: any) {
            return state.locale;
        },

        availableLocales(state: any) {
            return state.availableLocales.split(',');
        },

        fallbackLocale(state: any) {
            return state.fallbackLocale
        }
    },

    mutations: {
        SWITCH_LOCALE(state: any, locale: string) {
            state.locale = locale;
        }
    },

    actions: {
        switchLocale(context: any, locale: string) {
            context.commit('SWITCH_LOCALE', locale);
        }
    }
}