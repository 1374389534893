import axios from "@/axios";

export default {
  async getMainCategory(context: any) {
    const path = `category/home`;

    try {
      const mainCategory = await axios.get(`${path}`);
      const sortedCategory = mainCategory.data.sort((a: any, b: any) => {
        return b.weight - a.weight;
      });
      return context.commit("getMainCategory", sortedCategory);
    } catch (e: any) {
      console.error(e);
    }
  },
};
