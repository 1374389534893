export default {
    setCategories(state: any, payload: any): boolean {
        state.categories = payload
        return true;
    },
    setCategoriesId(state: any, payload: any) {
        if (!state.categoriesIdList.includes(payload)) {
            state.categoriesIdList.push(payload)
        }
    },
    removeFromCategoriesList(state: any, payload: any) {
        const index = state.categoriesIdList.indexOf(payload);
        if (index !== -1) {
            state.categoriesIdList.splice(index, 1);
        }
    },
    clearParams(state: any) {
        state.categoriesIdList = []
    },
    setParentCategory(state: any, payload: any) {
        state.parentCategory = payload
    },
    clearParentCategory(state: any) {
        state.parentCategory = null;
    }
}
