import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-600e6e2f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-content-center align-items-center" }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currency) = $event)),
      class: _normalizeClass([{ home: _ctx.home && _ctx.isHome }, "currency"]),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeCurrency && _ctx.changeCurrency(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currencyList, (currency) => {
        return (_openBlock(), _createElementBlock("option", {
          key: currency.code,
          value: currency.code
        }, _toDisplayString(currency.code), 9, _hoisted_2))
      }), 128))
    ], 34), [
      [_vModelSelect, _ctx.currency]
    ])
  ]))
}