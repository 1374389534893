import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_VideoBackground = _resolveComponent("VideoBackground")!
  const _component_Popular = _resolveComponent("Popular")!
  const _component_ShopCategory = _resolveComponent("ShopCategory")!
  const _component_CateringServices = _resolveComponent("CateringServices")!
  const _component_LuxuryGiftsCollection = _resolveComponent("LuxuryGiftsCollection")!
  const _component_History = _resolveComponent("History")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_VideoBackground, {
      src: _ctx.main?.headerWebVideo ? _ctx.main?.headerWebVideo : '',
      class: "video-player position-relative",
      sources: [
        {src: _ctx.main?.headerMobileVideo ? _ctx.main?.headerMobileVideo : '', res: 600, autoplay: true},
    ]
    }, {
      default: _withCtx(() => [
        _createVNode(_component_router_link, {
          style: {"position":"absolute","top":"50%","left":"50%","transform":"translate(-50%, -50%)","display":"inline-block","text-align":"center","padding":"0.5rem 1rem","border-radius":"0.5rem","font-size":"16px","border":"1px solid #fff","background-color":"#fff","color":"#003C73","font-weight":"400","text-decoration":"none","text-transform":"uppercase","letter-spacing":"0.1rem","box-shadow":"0 0.5rem 1rem rgba(0, 0, 0, 0.2)","transition":"all 0.3s ease-in-out","line-height":"1.4"},
          to: _ctx.getStorage ==='false' ? `/${_ctx.language}${_ctx.main?.shopLocal}` : `/${_ctx.language}${_ctx.main?.shopInternational}` 
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("home.shopNow")), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _: 1
    }, 8, ["src", "sources"]),
    _createVNode(_component_Popular),
    _createVNode(_component_ShopCategory),
    _createVNode(_component_CateringServices),
    _createVNode(_component_LuxuryGiftsCollection, { class: "mt-lg-4 mt-sm-2" }),
    _createVNode(_component_History, {
      class: _normalizeClass({ ar: _ctx.language === 'ar' })
    }, null, 8, ["class"])
  ]))
}