
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["closeShopping", "closeSide"],
  props: ["local"],
  data() {
    return {
      showSub: false,
    };
  },
  methods: {
    openSub(id: number) {
      this.showSub = true;
    },
  },
});
