<template>
  <div class="header-container " :class="{ home: home && false , 'mb-0' : home}">
    <header class="the-header" :class="{ home: home && false }">
      <Toggle @toggle="$emit('sidenavToggle')"/>
      <router-link
          class="logo-mobile"
          to="/index"
          :class="{ home: home  && false}"
      >
        <img
            src="@/assets/icons/main-logo.svg"
            alt="img"
        />
      </router-link>
      <div class="navigation-items">
        <ul class="nav-list">
          <li
              @mouseenter.prevent.self.capture="dropdown1 = true"
              class="nav-item"
              style="z-index: 15"
              :class="{ home: home && false }"
          >
            <div
                class="nav-link p-0"
                id="dropdown1"
                :class="{ home: home  && false }"
            >
              {{
                $t("header.shopping")
              }}<img
                class="Log-Down mx-1"
                alt="img"
                src="@/assets/images/header/Down.svg"
            />
            </div>
            <ul
                class="dropdown-menu dropdown-menu-start dropdown-zalatimo categories"
                :class="{
                show: dropdown1,
                ar: local === 'ar',
                home: home  && false ,
              }"
                aria-labelledby="dropdown1"
                v-show="dropdown1"
            >
              <Categories @close="dropdown1 = false"/>
            </ul>
          </li>
          <li>
            <div
                v-if="dropdown1"
                class="close-dropdown"
                @mouseenter.capture="dropdown1 = false"
            ></div>
            <div
                v-if="dropdown1"
                @mouseenter.capture="dropdown1 = false"
                class="backdrop"
            ></div>
          </li>
          <li class="nav-item">
            <router-link
                @click="dropdown1 = false"
                @mouseenter.prevent.self.capture="dropdown1 = false"
                :class="{ home: home   && false }"
                :to="{path:'/' + local + '/catering-services'}"
            >
              {{ $t("header.catering") }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
                @click="dropdown1 = false"
                @mouseenter.prevent.self.capture="dropdown1 = false"
                :class="{ home: home   && false  }"
                :to="'/' + local + '/all-products?categoryId=53'"
            >
              {{ $t("header.gourmet") }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
                @mouseenter.prevent.self.capture="dropdown1 = false"
                @click="dropdown1 = false"
                :class="{ home: home   && false  }"
                :to="{path:'/' + local + '/stores'}"

            >
              {{ $t("header.ourStores") }}
            </router-link>
          </li>
          <li
              class="nav-item"
              @mouseenter.prevent.self.capture="dropdown2 = true; dropdown1 = false"
              @mouseleave="dropdown2 = false"
          >
            <span
                class="dropdown-span"
                id="dropdown3"
                :class="{ home: home  && false }"
            >
              {{ $t("header.aboutus") }}
              <img
                  alt="img"
                  class="Log-Down mx-1"
                  src="@/assets/images/header/Down.svg"
              />
            </span>
            <ul
                class="dropdown-menu dropdown-menu-start"
                :class="{ show: dropdown2, ar: local === 'ar' }"
                aria-labelledby="dropdown3"
            >
              <li>
                <router-link class="item-dropdown dropdown-item"
                             :to="{path:'/' + local + '/about-us'}"

                >{{ $t("header.aboutus") }}
                </router-link>
              </li>
              <li>
                <router-link
                    class="item-dropdown dropdown-item"
                    :to="{path:'/' + local + '/our-history'}"

                >
                  {{ $t("header.ourhistory") }}
                </router-link>
              </li>
              <li>
                <router-link
                    class="item-dropdown dropdown-item"
                    :to="{path:'/' + local + '/certificate'}"
                >
                  {{ $t("header.certificate") }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </header>
  </div>
</template>

<script>
import Toggle from "@/components/navigation/TheSideNavToggle.vue";
import {mapGetters} from "vuex";
import Categories from "@/components/navigation/Categories.vue";

export default {
  name: "TheHeader",
  components: {
    Categories,
    Toggle,
  },
  data() {
    return {
      dropdown1: false,
      dropdown2: false,
      isHome: true,
    };
  },
  methods: {
    handleScroll() {
      if (window.scrollY === 0) {
        this.isHome = false;
      } else {
        this.isHome = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      local: "localization/locale",
    }),
    home() {
      return this.$route.name === "HomePage" || this.$route.name === "Home" || this.$route.name === "CateringServices";
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/helpers/_include.scss";

.header-container {
  width: 100%;
  margin-bottom: 160px;

  &.home {
    margin-bottom: 0;
  }

  .mb-100 {
    margin-bottom: 100px;
  }
}

.the-header {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  position: fixed;
  height: 70px;
  top: 40px;
  left: 0;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  background-color: $white;
  z-index: 10;
  box-sizing: border-box;
  transition: background-color 400ms;
  @include md-max {
    align-items: center;
    justify-content: start;
    padding: 10px 15px;
  }

  &.home {
    background-color: transparent;
  }

  padding-bottom: 10px;
}

.logo {
  margin: 0 10px;
  width: 50px;
  height: 50px;
}

.navigation-items {
  display: none;
}

@media (min-width: 769px) {
  .navigation-items {
    display: block;
  }
}

.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.nav-item {
  margin: 0 10px;
  height: 100%;
  line-height: 2;
  cursor: pointer;

  &.home {
    color: $white;
  }
}

.nav-item a {
  text-decoration: none;
  color: $secondary2;
  cursor: pointer;

  &.home {
    color: $white;
  }

  &.home {
    color: $white;
  }
}

.nav-link {
  &.home {
    color: $white !important;
  }
}

.nav-item a:hover,
.nav-item a:active,
{

  color: $primary !important;
}

.close-dropdown {
  position: fixed;
  top: 0;
  left: 0;
  height: 100px;
  width: 100%;
  z-index: 11;
  opacity: 0.3;
}

.backdrop {
  position: fixed;
  top: 101px;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba($primary, 0.75);
  z-index: 10;
  opacity: 0.3;
}

.categories {
  padding: 20px 5px;
}

.dropdown-zalatimo {
  width: 100%;
  left: 0;
  top: 60px;
  border: none;
  border-radius: 0 !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.dropdown-zalatimo.show {
  display: flex !important;
}

.dropdown-menu {
  &.home {
    background-color: transparent !important;

    .categories-sub {
      a {
        color: white !important;
      }
    }

    .categories-title {
      a {
        color: white !important;
      }
    }
  }
}

.nav-link {
  color: $secondary2;
}

.logo-mobile {
  display: none;
  margin-left: auto;
  @include md-max {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    width: 150px;
    height: 45px;
    @include sm-max {
      width: 100%;
      height: 32px;
    }
  }
}

.dropdown-span {
  color: $secondary2;
  cursor: pointer;

  &.home {
    color: $white;
  }
}
</style>
