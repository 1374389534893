import axios from '@/axios';
import ProductInterface from "@/interfaces/product.interface";

export interface type {
    modes: string,
    limit?: number
    offset?: number
    page?: number | null
    pageSize?: number
    maxPrice?: number
    minPrice?: number
    sort?: string
    productId?: number | null
    categoriesId?: number[],
    search?: string | null
}

export default async function getProducts(params: type): Promise<{
    data: ProductInterface[],
    count: number,
    currency: string

}> {
    let data = [];
    const path = `/products`
    const response = await axios.get(`${path}`,
        {
            params: params
        }
    );

    data = response.data;
    return data;
}


export async function getWheel() {
    const path = '/products/wheel';
    return await axios.get(`${path}`)

}
