
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "CateringServices",
  methods: {
    changePath(path: string) {
      this.$router.push(`/en${path}`);
      this.scrollToTop();
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  computed: {
    ...mapGetters({
      local: "localization/locale",
    }),
  },
});
