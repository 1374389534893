import {createRouter, createWebHistory} from 'vue-router';
import routes from "./routes";
import store from "@/store";

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: 'active-link',
    linkExactActiveClass: 'exact-active-link',
    routes
});

router.beforeEach((to, from, next) => {
    const authenticated = store.getters['auth/isAuthenticated'];
    (document as any).title = to?.meta?.title || 'Zalatimo';
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (authenticated) {
            next();
        } else {
            store.dispatch('auth/toggleLoginModal', true);
            next(from.path);
        }
    } else {
        next();
    }
});
export default  router;
