import {CartItem} from "@/interfaces/cart";

export default {
    cartItems(state: any): CartItem[] {
        return state?.cartItems;
    },
    cost(state: any): number {
        return state.cost;
    },
    currency(state: any): string {
        return state.currency;
    },
    cartItemsNumber(state: any): number {
        let count = 0;
        if (state?.cartItems && state?.cartItems.length > 0) {
            for (let i = 0; i < state?.cartItems?.length; i++) {
                count += state?.cartItems[i]?.quantity;
            }
        }
        return count;
    },
};
