
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    link: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: String,
      required: false,
      default: "/",
    },
    small: {
      type: Boolean,

      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,

      required: false,
      default: false,
    }
  },
});
