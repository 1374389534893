<template>
  <div class="light">
    <div class="container-fluid h-100">
      <div class="light-container w-100">
        <div :class="isOpen ? 'd-none d-sm-block' : ''">
          <InternationalDesktop/>
        </div>
        <router-link
            to="/index"
            class="d-block zalatimo-logo"
        >
          <img
              src="@/assets/icons/main-logo.svg"
              alt="img"
              class="logo"
          />
        </router-link>
        <ServesIconDesktop @openSearch="isOpen = !isOpen" :class="isOpen ? 'w-100':''"/>
      </div>
    </div>
  </div>
</template>

<script>
import ServesIconDesktop from "@/components/navigation/ServesIconDesktop.vue";
import InternationalDesktop from "@/components/navigation/InternationalDesktop.vue";

export default {
  components: {InternationalDesktop, ServesIconDesktop},
  computed: {
    home() {
      return this.$route.name === "HomePage" ||
          this.$route.name === "Home" ||
          this.$route.name === "CateringServices";
    },
  },
  data() {
    return {
      isHome: true,
      isOpen: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/helpers/_include.scss";

.light {
  background-color: $white;
  position: fixed;
  top: 0;
  height: 60px;
  z-index: 100;
  width: 100%;
  padding-top: 10px;
  transition: background-color 400ms;

  &.home {
    background-color: transparent;
  }

  &-container {
    display: flex;
    height: 100%;
    justify-content: space-between;
  }
}

.zalatimo-logo {
  img {
    width: fit-content;
    height: 3.5rem;
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
  }

  @include md-max {
    display: none !important;
  }
}
</style>
