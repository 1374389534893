export default {
    showModal(state: any): Promise<any> {
        return state.swap;
    },
    checkSession() {
        if (localStorage.getItem("international") === null) {
            return true
        }
        return false;
    },
    getSession() {
        if (localStorage.international) {
            return localStorage.international
        }
        return false;
    },
    checkStorage() {
        if (localStorage.getItem("international") !== null) {
            return true
        }
    },
    show(state: any) {
        return state.show;

    }
};
