import firebase from "firebase/compat/app";
import axios from "@/axios";

export default {
  namespaced: true,

  state: {
    loginModal: false,
    countryModal: true,
    signupModal: false,
    authenticated: false,
    user: {},
    idToken: null,
    firebaseUser: null,
    guestUid: null,
    log: false,
  },

  getters: {
    showLoginModal(state: any) {
      return state.loginModal;
    },

    showCountryModal(state: any) {
      return state.countryModal;
    },
    showSignupModal(state: any) {
      return state.signupModal;
    },

    isAuthenticated(state: any) {
      return state.authenticated;
    },

    user(state: any) {
      return state.user;
    },

    idToken(state: any) {
      return state.idToken;
    },
  },

  mutations: {
    TOGGLE_LOGIN_MODAL(state: any, status: boolean) {
      state.loginModal = status;
    },

    TOGGLE_COUNTRY_MODAL(state: any, status: boolean) {
      state.countryModal = status;
    },
    TOGGLE_SIGNUP_MODAL(state: any, status: boolean) {
      state.signupModal = status;
    },

    SET_STATUS(state: any, status: boolean) {
      state.authenticated = status;
    },

    SET_USER(state: any, user: any) {
      state.user = user;
    },

    SET_ID_TOKEN(state: any, idToken: string) {
      state.idToken = idToken;
    },
  },

  actions: {
    toggleLoginModal({ commit }: { commit: any }, status: boolean) {
      commit("TOGGLE_LOGIN_MODAL", status);
    },

    toggleSignupModal({ commit }: { commit: any }, status: boolean) {
      commit("TOGGLE_SIGNUP_MODAL", status);
    },

    toggleCountryModal({ commit }: { commit: any }, status: boolean) {
      commit("TOGGLE_COUNTRY_MODAL", status);
    },

    async signUpGuest({ state }: { state: any }) {
      await firebase.auth().onAuthStateChanged(function (user) {
        if (user && !user?.isAnonymous) {
          state.authenticated = true;
          firebase.auth()
            .currentUser?.getIdToken(true)
              .then(function (idToken) {
              state.idToken = `${idToken}`;
              return `${idToken}`;
            })
            .catch(function (error) {
              // Handle error
              location.reload();
              console.error(error);
            });
        } else {
          state.authenticated = false;
           firebase.auth()
            .signInAnonymously()
            .then((user) => {
              state.guestUid = user.user?.uid;
              firebase
                .auth()
                .currentUser?.getIdToken(true)
                .then(function (idToken) {
                  state.idToken = `${idToken}`;
                  return `${idToken}`;
                })
                .catch(function (error) {
                  // Handle error
                  console.error(error);
                  location.reload();
                });
            })
            .catch((error) => {
              console.error(error);
            });
        }
      });
    },

    async signup(
      { commit, state }: { commit: any; state: any },
      credentials: any
    ) {
      const { user: firebaseUser } = await firebase
        .auth()
        .createUserWithEmailAndPassword(
          credentials.email,
          credentials.password
        );

      state.firebaseUser = firebaseUser;
      const idToken = await firebaseUser?.getIdToken();
      commit("SET_ID_TOKEN", idToken);

      const { data: user } = await axios.post("authentication/register", {
        email: credentials.email,
        phoneNumber: credentials.phoneNumber,
        userName: credentials.firstName + " " + credentials.lastName,
        guestUid: state.guestUid,
        userUid: state.firebaseUser.uid,
      });

      if (user) {
        commit("SET_STATUS", true);
        commit("SET_USER", user);
        location.reload();
      }
    },

    async loginWithEmail(
      { commit, state }: { commit: any; state: any },
      credentials: any
    ) {
      const { user: firebaseUser } = await firebase
        .auth()
        .signInWithEmailAndPassword(credentials.email, credentials.password);

      state.firebaseUser = firebaseUser;
      const idToken = await firebaseUser?.getIdToken(true);
      state.log = true;
      commit("SET_ID_TOKEN", idToken);

      const { data: user } = await axios.post("authentication/login", {
        guestUid: state.guestUid,
      });

      commit("SET_STATUS", true);
      commit("SET_USER", user);
      location.reload();
    },

    async loginWithFacebook(
      { commit, state }: { commit: any; state: any },
      credentials: any
    ) {
      const provider = new firebase.auth.FacebookAuthProvider();
      const { user: firebaseUser } = await firebase
        .auth()
        .signInWithPopup(provider);

      state.firebaseUser = firebaseUser;
      const idToken = await firebaseUser?.getIdToken(true);
      state.log = true;
      commit("SET_ID_TOKEN", idToken);

      try {
        const { data: user } = await axios.post("authentication/login");
        commit("SET_STATUS", true);
        commit("SET_USER", user);
      } catch (e) {
        const [firstName, lastName]: any =
          firebaseUser?.displayName?.split(" ") || [];
        const { data: user } = await axios.post("authentication/register", {
          email: firebaseUser?.email,
          phoneNumber: "NA",
          userName: firebaseUser?.displayName,
          guestUid: state.guestUid,
        });

        commit("SET_STATUS", true);
        commit("SET_USER", user);
      }
      location.reload();
    },

    async loginWithGoogle({ commit, state }: { commit: any; state: any }) {
      const provider = new firebase.auth.GoogleAuthProvider();
      const { user: firebaseUser } = await firebase
        .auth()
        .signInWithPopup(provider);

      state.firebaseUser = firebaseUser;
      const idToken = await firebaseUser?.getIdToken(true);
      state.log = true;
      commit("SET_ID_TOKEN", idToken);

      try {
        const { data: user } = await axios.post("authentication/login", {
          guestUid: state.guestUid,
        });

        commit("SET_STATUS", true);
        commit("SET_USER", user);
      } catch (e) {
        const [firstName, lastName]: any =
          firebaseUser?.displayName?.split(" ") || [];

        const { data: user } = await axios.post("authentication/register", {
          email: firebaseUser?.email,
          phoneNumber: "NA",
          userName: firebaseUser?.displayName,
          guestUid: state.guestUid,
        });

        commit("SET_STATUS", true);
        commit("SET_USER", user);
      }
      location.reload();
    },

    async resetPassword({ commit }: { commit: any }, email: string) {
      await firebase.auth().sendPasswordResetEmail(email);
    },

    async logout({ commit, state }: { commit: any; state: any }) {
      await firebase.auth().signOut();
      state.log = false;
      commit("SET_STATUS", false);
      commit("SET_USER", {});
      commit("SET_ID_TOKEN", null);
      location.reload();
    },
  },
};
