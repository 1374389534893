import {CartItem} from "@/interfaces/cart";

export default {
    setCartItems(state: any, payload: CartItem[]): boolean {
        state.cartItems = payload
        return true;
    },
    setCartPrice(state: any, payload: number): boolean {
        state.cost = payload
        return true;
    },
    setCartCurrency(state: any, payload: string): boolean {
        state.currency = payload
        return true;
    }
}