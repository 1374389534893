
import {defineComponent} from "vue";
import {mapGetters} from "vuex";

export default defineComponent({
  emits: ["closeShopping", "closeSide"],
  props: ["local"],
  computed: {
    ...mapGetters({
      appLocale: 'localization/locale'
    }),
    mainUrl() {
      return `/${this.appLocale}/all-products`
    }
  },
  data() {
    return {
      shopping: [
        {
          title: this.$t("category.mediterranean"),
          id: 1,
          subCategory: [
            {name: this.$t("category.baklava"), id: 2},
            {name: this.$t("category.mamoul"), id: 9},
            {name: this.$t("category.barazek"), id: 12},
            {name: this.$t("category.nougat"), id: 14},
            {name: this.$t("category.traditionalTreats"), id: [10, 11]},
            {name: this.$t("category.driedFruit"), id: 13},
            {name: this.$t("category.ramadanSweets"), id: 62},
            {name: this.$t("category.sesameTreats"), id: 10},

          ],
        },
        {
          title: this.$t("category.cakePatisserie"),
          id: 15,
          subCategory: [
            {name: this.$t("category.cakePatisserie"), id: 15},
            {name: this.$t("category.cake"), id: 65},
            {name: this.$t("category.iceCream"), id: 23},
            {name: this.$t("category.trifles"), id: 22},
            {name: this.$t("category.customCake"), id: 27},
            {name: this.$t("category.artisanalDelights"), id: 66},
          ],
        },
        {
          title: this.$t("category.chocolates"),
          id: 34,
          subCategory: [
            {name: this.$t("category.chocolates"), id: 34},
            {name: this.$t("category.chocolatesDragees"), id: 35},
            {name: this.$t("category.pralines"), id: 70},
            {name: this.$t("category.truffles"), id: 71},
            {name: this.$t("category.dragees"), id: 72},
            {name: this.$t("category.arrangements"), id: 36},
            {name: this.$t("category.chocolateProducts"), id: 73},
            {name: this.$t("category.occasions"), id: 37},
            {name: this.$t("category.wedding"), id: 38},
            {name: this.$t("category.Graduation"), id: 38},
            {name: this.$t("category.born"), id: 40},
            {name: this.$t("category.giftVouchers"), id: 61},
          ],
        },
        {
          title: this.$t("category.traditional"),
          id: 53,
          subCategory: [
            {name: this.$t("category.traditional"), id: 53},
            {name: this.$t("category.oil"), id: 54},
            {name: this.$t("category.zaatar"), id: 56},
            {name: this.$t("category.syrups"), id: 57},
            {name: this.$t("category.honey"), id: 58},
            {name: this.$t("category.natural"), id: 55},
          ],
        },
        {
          title: this.$t("category.specialPacking"),
          id: 45,
          subCategory: [
            {name: this.$t("category.specialPacking"), id: 45},
            {name: this.$t("category.emblems"), id: 48},
            {name: this.$t("category.milestones"), id: 49},
            {name: this.$t("category.woodenGlass"), id: 50},
          ],
        },
      ],
      subCategory: [],
      showSub: false,
    };
  },
  methods: {
    openSub(id: number) {
      this.showSub = true;
      this.subCategory = [];
      this.shopping.map((shop: any) => {
        if (shop.id == id) {
          this.subCategory = shop.subCategory;
        }
      });
    },
    changeRoute(name: any) {
      this.$emit('closeSide')
      this.$router.push('/' + this.appLocale + '/' + name);
    },
  },
});
