export default {
    namespaced: true,

    state: {
        allInclusive: false,
        currency: [],
        language: []
    },

    getters: {
        getAllInclusive(state: any) {
            return state.allInclusive
        },
        getAllCurrencies(state: any) {
            return state.currency
        },
        getAllLanguages(state: any) {
            return state.language
        },
        getShipping() {
            // Json parse used to change true||false from string => boolean because data saved as string in locale storage
            const response = localStorage.getItem("international") ?? ''
            return JSON.parse(response);
        }
    },

    mutations: {
        setMainData(state: any, mainData: any) {
            state.allInclusive = mainData.allInclusive;
            state.language = mainData.language;
            state.currency = mainData.currency
        }
    },

    actions: {
        getMainData(context: any, mainInfo: any) {
            context.commit('setMainData', mainInfo)
        }
    }
}
