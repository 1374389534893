import axios from "@/axios-plugin";
import ProductCard from "@/components/common/ProductCard.vue";
import BaseButton from "@/components/ui/BaseButton.vue";
import notifier from "@/notifier";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import * as mdbVue from "mdb-vue-ui-kit";
import { createApp } from "vue";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import vueCountryRegionSelect from "vue3-country-region-select";
import Vue3Transitions from "vue3-transitions";
import App from "./App.vue";
import "./firebase";
import i18n from "./i18n";
import router from "./router";
import store from "./store";

const app = createApp(App);
app.use(Vue3Transitions);
app.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyC8oL-2-JmMvhS0sWUISCJWL1xkaAxIjtU\n",
    },
});
app.use(router);
app.use(store);
app.use(i18n);
app.use(vueCountryRegionSelect);
app.use(notifier);
app.use(axios);



//****************************** Base Components
for (const component in mdbVue) {
    app.component(component, mdbVue[component]);
}
app.component("v-select", vSelect);
app.component("product-card", ProductCard);
app.component("BaseButton", BaseButton);

//***************************


app.config.globalProperties.$myGlobalVariable = {
    defaultImage: 'default/mediterranean.png'
};

//global error handler
app.config.errorHandler = (err, vm, info) => {
    console.error(err);
    console.error(vm);
    console.error(info);
}

router.beforeEach(
    (
        to: RouteLocationNormalized,
        from: RouteLocationNormalized,
        next: NavigationGuardNext
    ) => {
        const { locale } = to.params;
        const availableLocales = store.getters["localization/availableLocales"];
        const fallbackLocale = store.getters["localization/locale"]
            ? store.getters["localization/locale"]
            : store.getters["localization/fallbackLocale"];
        const fallbackPath = `/${fallbackLocale}${to.path}`;

        if (!availableLocales.includes(locale)) return next(fallbackPath);

        if (store.getters["localization/locale"] !== locale) {
            store.dispatch("localization/switchLocale", locale);
        }

        return next();
    }
);

app.mount("#app");
