export default {
    categories(state: any): Promise<any> | null {
        if (state.categories) {
            return state.categories.filter((item: any) => {
                    return item.id != 64;
                }
            );
        }
        return null
    },
    categoriesId(state: any) {
        return state.categoriesIdList
    },
    parentCategory(state: any) {
        return state.parentCategory
    }
}
