import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SlideBar = _resolveComponent("SlideBar")!

  return (_ctx.products.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SlideBar, {
          items: _ctx.products,
          size: _ctx.size,
          mainTitle: _ctx.mainTitle,
          currency: _ctx.currency
        }, null, 8, ["items", "size", "mainTitle", "currency"])
      ]))
    : _createCommentVNode("", true)
}