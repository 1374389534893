export default {
    namespaced: true,

    state: {
        notify: {
            type: null,
            message: null
        }
    },

    getters: {
        show(state: any) {
            return !!state.notify.message;
        },

        notification(state: any) {
            return state.notify;
        }
    },

    mutations: {
        SET_NOTIFICATION(state: any, notification: any) {
            state.notify = notification;
        }
    },

    actions: {
        setNotification({commit}: { commit: any }, notifcation: any) {
            commit('SET_NOTIFICATION', notifcation);
        }
    }
}