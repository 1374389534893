
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  helpers,
  minLength,
  maxLength,
} from "@vuelidate/validators";
import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";

export default defineComponent({
  setup: () => ({
    v$: useVuelidate(),
  }),

  data() {
    return {
      loading: false,
      credentials: {
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        messages: false,
        phoneNumber: null,
      },
      passwordInputType: "password",
      options: { placeholder: this.$t("signUp.phone") },
    };
  },

  validations() {
    return {
      credentials: {
        firstName: {
          required: helpers.withMessage("Please enter first name.", required),
        },
        lastName: {
          required: helpers.withMessage("Please enter last name.", required),
        },
        email: {
          required: helpers.withMessage(
            "Please enter your email address.",
            required
          ),
          email: helpers.withMessage(
            "Please enter a valid email address.",
            email
          ),
        },
        phoneNumber: {
          required: helpers.withMessage(
            "Please enter your Phone Number.",
            required
          ),
          minLength: helpers.withMessage(
            " Please Make Sure That Your Phone Number Is Valid",
            minLength(10)
          ),
          maxLength: helpers.withMessage(
            " Please Make Sure That Your Phone Number Is Valid",
            maxLength(15)
          ),
        },
        password: {
          required: helpers.withMessage("Please enter a password.", required),
          minLength: helpers.withMessage(
            "Password must be 6 characters or more.",
            minLength(6)
          ),
        },
      },
    };
  },
  components: {
    VueTelInput,
  },
  computed: {
    ...mapGetters({
      showSignupModal: "auth/showSignupModal",
    }),
  },

  methods: {
    ...mapActions({
      toggleSignupModal: "auth/toggleSignupModal",
      toggleLoginModal: "auth/toggleLoginModal",
      loginWithFacebook: "auth/loginWithFacebook",
      loginWithGoogle: "auth/loginWithGoogle",
      signup: "auth/signup",
    }),
    onInput(phone: any, phoneObject: any) {
      if (phoneObject?.formatted) {
        this.credentials.phoneNumber = phoneObject.number;
      }
    },
    togglePasswordInputType() {
      this.passwordInputType =
        this.passwordInputType === "password" ? "text" : "password";
    },

    async facebookSignup() {
      try {
        await this.loginWithFacebook();
        this.$notifySuccessLogin();
        this.toggleSignupModal(false);
      } catch (e) {
        this.$notifyFailLogin();
      }
    },

    async googleSignup() {
      try {
        await this.loginWithGoogle();
        this.$notifySuccessLogin();
        this.toggleSignupModal(false);
      } catch (e) {
        this.$notifyFailLogin();
      }
    },

    async emailSignup() {
      const formIsValidated = await this.v$.$validate();
      if (!formIsValidated) return;

      this.loading = true;

      try {
        await this.signup(this.credentials);
        this.$notifySuccessRegister();
        this.toggleSignupModal(false);
      } catch ({ code }) {
        this.$notifyFailRegister(code);
      } finally {
        this.loading = false;
      }
    },

    showLoginModal() {
      this.toggleSignupModal(false);
      this.toggleLoginModal(true);
    },

    resetForm() {
      this.loading = false;
      this.credentials = {
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        password: null,
        messages: false,
      };
      this.passwordInputType = "password";
      this.v$.$reset();
    },
  },
});
