import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/header/Down.svg'


const _withScopeId = n => (_pushScopeId("data-v-29c9c5ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "nav-list"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 1,
  class: "nav-list scroll"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", null, [
    (!_ctx.showSub)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          _createElementVNode("li", null, [
            _createElementVNode("img", {
              class: _normalizeClass([_ctx.local === 'ar' ? 'right-drop' : 'left-drop', "side-back"]),
              alt: "img",
              height: "25",
              width: "25",
              src: _imports_0,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeShopping')))
            }, null, 2)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shopping, (shop) => {
            return (_openBlock(), _createElementBlock("li", {
              key: shop.id,
              class: "nav-item text-start pointer-cursor",
              onClick: ($event: any) => (_ctx.openSub(shop.id))
            }, [
              _createElementVNode("div", null, [
                _createTextVNode(_toDisplayString(shop.title) + " ", 1),
                _createElementVNode("img", {
                  class: _normalizeClass(_ctx.local === 'ar' ? 'left-drop' : 'right-drop'),
                  alt: "img",
                  height: "13",
                  width: "13",
                  src: _imports_0
                }, null, 2)
              ])
            ], 8, _hoisted_2))
          }), 128)),
          _createElementVNode("li", {
            class: "nav-item text-start pointer-cursor",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeRoute('all-products?categoryId=63')))
          }, _toDisplayString(_ctx.$t("category.knafeh")), 1),
          _createElementVNode("li", {
            class: "nav-item text-start pointer-cursor",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeRoute('all-products?categoryId=62')))
          }, _toDisplayString(_ctx.$t("category.ramadanSweets")), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.showSub)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
          _createElementVNode("img", {
            class: _normalizeClass([_ctx.local === 'ar' ? 'right-drop' : 'left-drop', "side-back"]),
            alt: "img",
            height: "25",
            width: "25",
            src: _imports_0,
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showSub = false))
          }, null, 2),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subCategory, (sub) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "nav-item text-start",
              key: sub.id,
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('closeSide')))
            }, [
              _createVNode(_component_router_link, {
                to: { path: _ctx.mainUrl , query: { categoryId: sub.id } }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(sub.name), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}