import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import {CartItem} from "@/interfaces/cart";

export default {
    namespaced: true,
    state() {
        return {
            cartItems: [] as CartItem[],
            cost: 0 as number,
            currency: "JOD" as string
        };
    },
    mutations,
    actions,
    getters
};