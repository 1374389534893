
import {defineComponent} from "vue";
import useVuelidate from '@vuelidate/core';
import {required, email, helpers} from '@vuelidate/validators';
import {mapActions} from "vuex";

export default defineComponent({
  props: ['show'],

  setup: () => ({
    v$: useVuelidate()
  }),

  data() {
    return {
      loading: false,
      email: null
    };
  },

  validations() {
    return {
      email: {
        required: helpers.withMessage('Please enter your email address.', required),
        email: helpers.withMessage('Please enter a valid email address.', email)
      }
    };
  },

  methods: {
    ...mapActions({
      toggleLoginModal: 'auth/toggleLoginModal',
      resetPassword: 'auth/resetPassword'
    }),

    async submit() {
      const formIsValidated = await this.v$.$validate();
      if (!formIsValidated) return;

      this.loading = true;

      try {
        await this.resetPassword(this.email);
        this.$notifySuccessPasswordResetLink();
      } catch ({code}) {
        this.$notifyFailResetPassword(code);
      } finally {
        this.loading = false;
      }
    },

    backToLogin() {
      this.$emit('close');
      this.toggleLoginModal(true);
    },

    resetForm() {
      this.loading = false;
      this.email = null;
      this.v$.$reset();
    }
  }
});
