import axios from "@/axios";
import {Cart} from "@/interfaces/cart";


export default {
    getCartItems: async function (context: any) {
        try {
            const {data} = await axios.get(`/carts/items`) as { data: Cart };
            context.commit('setCartItems', data.cartItems);
            context.commit('setCartPrice', data.cost);
            context.commit('setCartCurrency', data.currency)
            return data;
        } catch (e) {
            const i = e;
        }

    },

}