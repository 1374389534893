
import {defineComponent} from "vue";
import {mapGetters, mapActions} from "vuex";
import useVuelidate from "@vuelidate/core";
import {required, email, helpers} from "@vuelidate/validators";
import ForgotPassword from "@/components/auth/ForgotPassword.vue";

export default defineComponent({
  components: {
    ForgotPassword,
  },

  setup: () => ({
    v$: useVuelidate({$scope: "false"}),
  }),

  data() {
    return {
      loading: false,
      credentials: {
        email: null,
        password: null,
      },
      showForgotPassword: false,
    };
  },

  validations() {
    return {
      credentials: {
        email: {
          required: helpers.withMessage(
              "Please enter your email address.",
              required
          ),
          email: helpers.withMessage(
              "Please enter a valid email address.",
              email
          ),
        },
        password: {
          required: helpers.withMessage(
              "Please enter your password.",
              required
          ),
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      showLoginModal: "auth/showLoginModal",
    }),
  },

  methods: {
    ...mapActions({
      toggleLoginModal: "auth/toggleLoginModal",
      toggleSignupModal: "auth/toggleSignupModal",
      loginWithEmail: "auth/loginWithEmail",
      loginWithFacebook: "auth/loginWithFacebook",
      loginWithGoogle: "auth/loginWithGoogle",
    }),

    async facebookLogin() {
      try {
        await this.loginWithFacebook();
        this.$notifySuccessLogin();
        this.toggleLoginModal(false);
      } catch (e) {
        this.$notifyFailLogin();
      }
    },

    async googleLogin() {
      try {
        await this.loginWithGoogle();
        this.$notifySuccessLogin();
        this.toggleLoginModal(false);
      } catch (e) {
        this.$notifyFailLogin();
      }
    },

    async emailLogin() {
      const formIsValidated = await this.v$.$validate();
      if (!formIsValidated) return;

      this.loading = true;

      try {
        await this.loginWithEmail(this.credentials);
        this.$notifySuccessLogin();
        this.toggleLoginModal(false);
      } catch ({code}) {
        this.$notifyFailLogin();
      } finally {
        this.loading = false;
      }
    },

    showForgotPasswordModal() {
      this.toggleLoginModal(false);
      this.showForgotPassword = true;
    },

    showSignupModal() {
      this.toggleLoginModal(false);
      this.toggleSignupModal(true);
    },

    resetForm() {
      this.loading = false;
      this.credentials = {
        email: null,
        password: null,
      };
      this.v$.$reset();
    },
  },
});
