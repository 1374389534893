import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: 'AIzaSyAl6SDermG59uYoD7Q6P2qvA64NwCd3ugk',
    authDomain: 'zalatimo-brothers.firebaseapp.com',
    projectId: 'zalatimo-brothers',
    storageBucket: 'zalatimo-brothers.appspot.com',
    messagingSenderId: '713399537621',
    appId: '1:713399537621:web:7e0485dd6ccf88b003f75c',
    measurementId: 'G-CEVGB9Y5DN'
};

const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
