
import { defineComponent } from "vue";
import LayoutNewFooter from "@/components/layout/LayoutNewFooter.vue";
import { mapActions, mapGetters } from "vuex";
import CountryModal from "@/components/auth/CountryModal.vue";
import TheHeader from "@/components/navigation/TheHeader.vue";
import TheSidenav from "@/components/navigation/TheSidenav.vue";
import LightHeader from "@/components/navigation/LightHeader.vue";
import Signup from "@/components/auth/Signup.vue";
import Login from "@/components/auth/Login.vue";

export default defineComponent({
  components: {
    Login,
    Signup,
    LightHeader,
    TheSidenav,
    TheHeader,
    LayoutNewFooter,
    CountryModal,

  },
  data() {
    return {
      loading: true,
      international: false,
      displayNav: false
    };
  },
  computed: {
    ...mapGetters({
      language: "localization/locale",
    }),
  },
  async created() {
    if (!sessionStorage.getItem("international")) {
      this.international = true;
      this.toggleModal(1);
    } else {
      this.toggleModal(0);
    }
    await this.checkSign();
    setInterval(() => {
      this.checkSign();
    }, 1000 * 60 * 15);
  },
  methods: {
    ...mapActions({
      signUpGuest: "auth/signUpGuest",
      toggleModal: "countryModal/toggleModal",
    }),
    async checkSign() {
      await this.signUpGuest();
    },
  },
});
