<template>
  <div class="sidenav-container">
    <div v-if="show" class="sidenav-backdrop" @click="$emit('close')"></div>

    <transition name="animation">
      <div
        v-if="show"
        class="sidenavBar"
        :class="{
          ar: local === 'ar',
          sidenavBarAr: local === 'ar',
          sidenavBarEn: local === 'en',
        }"
      >
        <ul class="list nav-list  mt-5">
          <li class="nav-item d-flex justify-content-center mt-5" @click="$emit('close')">
            <router-link to="/index" class="text-center">
              <img src="@/assets/icons/zalatimo.svg" alt="img" class="logo" />
            </router-link>
          </li>
        </ul>
        <ul v-if="openAbout" class="list nav-list  mt-5">
          <AboutSIdeNav
            @closeShopping="openAbout = false"
            :local="local"
            @closeSide="
              $emit('close');
              openAbout = false;
            "
          />
        </ul>
        <ul v-if="!openAbout" class="nav-list">
          <img
            :class="local === 'ar' ? 'right-drop' : 'left-drop'"
            alt="img"
            class="side-back"
            height="25"
            width="25"
            src="@/assets/images/header/Down.svg"
            @click="$emit('close')"
          />
        </ul>
        <ul v-if="open" class="list nav-list">
          <CategorySideNav
            @closeShopping="open = false"
            :local="local"
            @closeSide="
              $emit('close');
              open = false;
            "
          />
        </ul>
        <ul v-if="!open && !openAbout" class="nav-list">
          <img
            :class="local === 'ar' ? 'right-drop' : 'left-drop'"
            alt="img"
            class="side-back"
            height="25"
            width="25"
            src="@/assets/images/header/Down.svg"
            @click="$emit('close')"
          />


          <li class="nav-item mt-5" @click="open = true">
            <div class="w-100 d-flex justify-content-start align-items-center gap-3">
              {{ $t("header.shopping")}}<img
                :class="local === 'ar' ? 'left-drop' : 'right-drop'"
                alt="img"
                height="13"
                width="13"
                src="@/assets/images/header/Down.svg"/>
            </div>
          </li>

          <li class="nav-item" @click="$emit('close')">
            <router-link to="/catering-services">
              {{ $t("header.catering") }}
            </router-link>
          </li>
          <li class="nav-item" @click="$emit('close')">
            <router-link :to="'/' + local + '/all-products?categoryId=53'">
              {{ $t("header.gourmet") }}
            </router-link>
          </li>
          <li class="nav-item" @click="$emit('close')">
            <router-link to="/stores">
              {{ $t("header.ourStores") }}
            </router-link>
          </li>

          <li class="nav-item" @click="openAbout = true">
            <router-link to="/about-us" class="d-flex justify-content-start align-items-center gap-3 w-100">
              {{ $t("header.aboutus") }}<img
              :class="local === 'ar' ? 'left-drop' : 'right-drop'"
              alt="img"
              height="13"
              width="13"
              src="@/assets/images/header/Down.svg"
            />
            </router-link>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>
<script>
import CategorySideNav from "@/components/navigation/CategorySideNav";
import AboutSIdeNav from "@/components/navigation/AboutSIdeNav";
import { mapGetters } from "vuex";

export default {
  name: "TheSidenav",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
      openAbout: false,
    };
  },
  components: { CategorySideNav, AboutSIdeNav },
  computed: {
    ...mapGetters({
      local: "localization/locale",
    }),
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/helpers/_include.scss";

.sidenav-container {
  height: 100%;
  width: 100%;

  .list {
    list-style: none;
  }

  .left-drop {
    transform: rotate(90deg);
    margin: 0px;
    padding: 0px;
    cursor: pointer;
  }

  .right-drop {
    transform: rotate(-90deg);
    cursor: pointer;
  }

  .logo {
    width: 70px;
    height: 70px;
  }

  .sidenav-backdrop {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
  }

  .sidenavBar {
    height: 100%;
    width: 300px;
    background-color: white;
    z-index: 10000;
    box-sizing: border-box;
    padding: 30px;
  }

  .sidenavBarEn {
    position: fixed;

    top: 0;
    left: 0;
  }

  .sidenavBarAr {
    position: fixed;

    top: 0;
    right: 0;
  }

  .slide-side-ar-enter-active,
  .slide-side-ar-leave-active {
    transition: all 0.3s ease-out;
  }

  .animation-enter-active,
  .animation-leave-active {
    transition: 1s ease all;
  }

  .animation-enter-from,
  .animation-leave-to {
    transform: translateX(-270px);
  }

  .animation-enter-to {
    transform: translateX(0);
  }

  .nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .nav-item {
    display: flex;
    width: 100%;
    cursor: pointer;
    justify-content: flex-start;
    align-items: center;
    color: $secondary2;
    @include font($font-sm);
    margin-bottom: 2rem;
    gap: 1rem;
    @include md-max{
      font-size: 1.4rem;
    }
  }

  .nav-item a {
    display: block;
    width:100%;
    text-decoration: none;
    color: $secondary2;
    line-height: 1;
    @include font($font-sm);
    @include md-max{
      font-size: 1.4rem;
    }
  }

  .nav-item a:hover,
  .nav-item a:active {
    color: $primary;
  }
}

.side-back {
  position: absolute;
  top: 30px;
  left: 15px;
  z-index: 1;
}
</style>
