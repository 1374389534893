
import {defineComponent} from "vue";
import {getCurrencyLanguage} from "@/services/language.service";
import {mapActions, mapGetters} from "vuex";

export default defineComponent({
  data() {
    let currency = localStorage.currency;
    if (!currency) {
      currency = "JOD";
    }
    return {
      currency,
      currencyList: [],
      isHome: true,
    };
  },
  methods: {
    ...mapActions({
      getMainData: 'main/getMainData',
      mainData: "zalatimo/setMainData"
    }),
    changeCurrency() {
      localStorage.currency = this.currency;
      location.reload();
    },
    handleScroll() {
      if (window.scrollY === 0) {
        this.isHome = false;
      } else {
        this.isHome = false;
      }
    },
  },
  computed: {
    home() {
      return this.$route.name === "HomePage" ||
      this.$route.name === "Home" ||
      this.$route.name === "CateringServices"
          ? false
          : false;
    },
  },
  async created() {
    let data = await getCurrencyLanguage();
    await this.mainData(data);

    await this.getMainData(data)
    this.currencyList = data.currency;
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
});
