<template>
  <li class="container">
    <div class="row">
      <div class="col-lg-3">
        <p @click="$emit('close')" class="categories-title">
          <router-link :to="{ path: productUrl, query: { categoryId: 1 } }">
            {{ $t("category.mediterranean") }}
          </router-link>
        </p>
        <ul @click="$emit('close')" class="categories-list">
          <li>
            <router-link
                :to="{ path: productUrl, query: { categoryId: 2 } }"
            >
              {{ $t("category.baklava") }}
            </router-link>
          </li>
          <li>
            <router-link
                :to="{ path: productUrl, query: { categoryId: 9 } }"
            >
              {{ $t("category.mamoul") }}
            </router-link>
          </li>
          <li>
            <router-link
                :to="{ path: productUrl, query: { categoryId: 12 } }"
            >
              {{ $t("category.barazek") }}
            </router-link>
          </li>
          <li>
            <router-link
                :to="{ path: productUrl, query: { categoryId: 14 } }"
            >
              {{ $t("category.nougat") }}
            </router-link>
          </li>
          <li>
            <router-link
                :to="{ path: productUrl, query: { categoryId: [10,11] } }"
            >
              {{ $t("category.traditionalTreats") }}
            </router-link>
          </li>
          <li>
            <router-link
                :to="{ path: productUrl, query: { categoryId: 13 } }"
            >
              {{ $t("category.driedFruit") }}
            </router-link>
          </li>

        </ul>
        <div class="line"></div>
        <p class="categories-title">
          <router-link
              :to="{ path: productUrl, query: { categoryId: 62 } }"
          >
            {{ $t("category.ramadanSweets") }}
          </router-link>
        </p>
        <div class="line"></div>
        <p class="categories-title" @click="$emit('close')">
          <router-link :to="{ path: productUrl, query: { categoryId: 63 } }">
            {{ $t("category.knafeh") }}
          </router-link>
        </p>
      </div>
      <div class="col-lg-3">
        <p @click="$emit('close')" class="categories-title">
          <router-link
              :to="{ path: productUrl, query: { categoryId: 15 } }"
          >
            {{ $t("category.cakePatisserie") }}
          </router-link>
        </p>
        <ul @click="$emit('close')" class="categories-list">
          <li>
            <router-link
                :to="{ path: productUrl, query: { categoryId: 65 } }"
            >
              {{ $t("category.cake") }}
            </router-link>
          </li>
          <li>
            <router-link
                :to="{ path: productUrl, query: { categoryId: 23 } }"
            >
              {{ $t("category.iceCream") }}
            </router-link>
          </li>
          <li>
            <router-link
                :to="{ path: productUrl, query: { categoryId: 22 } }"
            >
              {{ $t("category.trifles") }}
            </router-link>
          </li>
          <li>
            <router-link
                :to="{ path: productUrl, query: { categoryId: 27 } }"
            >
              {{ $t("category.customCake") }}
            </router-link>
          </li>
          <li>
            <router-link
                :to="{ path: productUrl, query: { categoryId: 66 } }"
            >
              {{ $t("category.artisanalDelights") }}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="col-lg-3">
        <p @click="$emit('close')" class="categories-title">
          <router-link
              :to="{ path: productUrl, query: { categoryId: 34 } }"
          >
            {{ $t("category.chocolates") }}
          </router-link>
        </p>
        <ul @click="$emit('close')" class="categories-list">


          <li class="categories-sub">
            <router-link
                :to="{ path: productUrl, query: { categoryId: 35 } }"
            >
              {{ $t("category.chocolatesDragees") }}
            </router-link>
          </li>
          <li>
            <ul class="categories-list ps-3">
              <li>
                <router-link
                    :to="{ path: productUrl, query: { categoryId: 70 } }"
                >
                  {{ $t("category.pralines") }}
                </router-link>
              </li>
              <li>
                <router-link
                    :to="{ path: productUrl, query: { categoryId: 71 } }"
                >
                  {{ $t("category.truffles") }}
                </router-link>
              </li>
              <li>
                <router-link
                    :to="{ path: productUrl, query: { categoryId: 72 } }"
                >
                  {{ $t("category.dragees") }}
                </router-link>
              </li>
              <li>
                <router-link
                    :to="{ path: productUrl, query: { categoryId: 36 } }"
                >
                  {{ $t("category.arrangements") }}
                </router-link>
              </li>
              <li>
                <router-link
                    :to="{ path: productUrl, query: { categoryId: 73 } }"
                >
                  {{ $t("category.chocolateProducts") }}
                </router-link>
              </li>
            </ul>
          </li>


          <li class="categories-sub">
            <router-link
                :to="{ path: productUrl, query: { categoryId: 37 } }"
            >
              {{ $t("category.occasions") }}
            </router-link>
          </li>
          <li>
            <ul class="categories-list ps-3">
              <li>
                <router-link
                    :to="{ path: productUrl, query: { categoryId: 38 } }"
                >
                  {{ $t("category.wedding") }}
                </router-link>
              </li>
              <li>
                <router-link
                    :to="{ path: productUrl, query: { categoryId: 39 } }"
                >
                  {{ $t("category.Graduation") }}
                </router-link>
              </li>
              <li>
                <router-link
                    :to="{ path: productUrl, query: { categoryId: 40 } }"
                >
                  {{ $t("category.born") }}
                </router-link>
              </li>
            </ul>
          </li>
          <li class="categories-sub">
            <router-link
                :to="{ path: productUrl, query: { categoryId: 61 } }"
            >
              {{ $t("category.giftVouchers") }}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="col-lg-3">
        <p @click="$emit('close')" class="categories-title">
          <router-link
              :to="{ path: productUrl, query: { categoryId: 45 } }"
          >
            {{ $t("category.specialPacking") }}
          </router-link>
        </p>
        <ul @click="$emit('close')" class="categories-list">
          <li>
            <router-link
                :to="{ path: productUrl, query: { categoryId: 48 } }"
            >
              {{ $t("category.emblems") }}
            </router-link>
          </li>
          <li>
            <router-link
                :to="{ path: productUrl, query: { categoryId: 49 } }"
            >
              {{ $t("category.milestones") }}
            </router-link>
          </li>
          <li>
            <router-link
                :to="{ path: productUrl, query: { categoryId: 50 } }"
            >
              {{ $t("category.woodenGlass") }}
            </router-link>
          </li>
        </ul>
        <div @click="$emit('close')" class="line"></div>
        <p @click="$emit('close')" class="categories-title">
          <router-link
              :to="{ path: productUrl, query: { categoryId: 53 } }"
          >
            {{ $t("category.traditional") }}
          </router-link>
        </p>
        <ul @click="$emit('close')" class="categories-list">
          <li>
            <router-link
                :to="{ path: productUrl, query: { categoryId: 54 } }"
            >
              {{ $t("category.oil") }}
            </router-link>
          </li>
          <li>
            <router-link
                :to="{ path: productUrl, query: { categoryId: 56 } }"
            >
              {{ $t("category.zaatar") }}
            </router-link>
          </li>
          <li>
            <router-link
                :to="{ path: productUrl, query: { categoryId: 57 } }"
            >
              {{ $t("category.syrups") }}
            </router-link>
          </li>
          <li>
            <router-link
                :to="{ path: productUrl, query: { categoryId: 58 } }"
            >
              {{ $t("category.honey") }}
            </router-link>
          </li>
          <li>
            <router-link
                :to="{ path: productUrl, query: { categoryId: 55 } }"
            >
              {{ $t("category.natural") }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </li>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      locale: 'localization/locale'
    }),
    productUrl() {
      return `/${this.locale}/all-products`;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/helpers/_include.scss";

.categories {
  &-list {
    list-style: none;
    padding: 0;

    li {
      list-style: none;

      a {
        color: $secondary2;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: $primary;
        }
      }

      margin-bottom: 5px;
    }

    .categories-sub {
      a {
        color: $secondary1;
        @include font($font-md)
      }
    }
  }

  &-title {
    a {
      @include font($font-md);
      color: $primary2;
    }
  }

  .line {
    margin: 10px auto;
  }

  &-sub {
    a {
      color: $secondary1;
      @include font($font-md)
    }
  }
}

.dropdown-menu {
  &.home {
    background-color: transparent !important;

    .categories-title {
      a {
        color: white !important;
      }
    }

    .categories-list {
      li {
        a {
          color: #fff;
        }
      }
    }
  }
}

</style>
