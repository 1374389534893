<template>
  <section class="pt-2">
    <CountryModal/>
    <div
        class="d-flex international-container align-items-center"
        v-if="getStorage === 'false'"
    >
      <div @click="changeCountry" class="d-flex align-items-center">
        <p class="mb-0 shipping-to">
          {{ $t("modal.shippingTo") }}
        </p>
        <img
            class="text-success mx-2 world"
            src="@/assets/icons/jordan.svg"
            alt="jordan"
            height="16"
            width="16"
        />
        <p class="international">
          {{ $t("header.jordan") }}
        </p>
      </div>
      <span class="mx-2 locale">|</span>
      <div
          class="locale"
          @click="changeAppLocale(appLocale === 'en' ? 'ar' : 'en')"
      >
        <label class="text-uppercase pointer"> {{ appLocale === 'en' ? 'العربية' : 'English' }}</label>
      </div>
    </div>
    <div v-else class="d-flex international-container align-items-center">
      <div @click="changeCountry" class="d-flex align-items-center">
        <p class="mb-0 shipping-to">
          {{ $t("modal.shippingTo") }}
        </p>
        <img
            class="text-success mx-2 world"
            src="@/assets/icons/worldwide.svg"
            alt="worldwide"
        />
        <p class="international">
          {{ $t("header.International") }}
        </p>
      </div>
      <span class="mx-2 locale">|</span>
      <div
          class="locale"
          @click="changeAppLocale(appLocale === 'en' ? 'ar' : 'en')"
      >
        <label class="text-uppercase pointer"> {{ appLocale === 'en' ? 'العربية' : 'English' }}</label>
      </div>
    </div>
  </section>
</template>
<script>
import {defineComponent} from "vue";
import {mapGetters, mapActions} from "vuex";
import CountryModal from "@/components/auth/CountryModal.vue";

export default defineComponent({
  name: "InternationalDesktop",
  data() {
    return {
      isHome: true,
    };
  },
  computed: {
    ...mapGetters({
      appLocale: "localization/locale",
      getStorage: "countryModal/getSession",
    }),
    home() {
      return (
          (this.$route.name === "HomePage" ||
              this.$route.name === "Home" ||
              this.$route.name === "CateringServices") && this.isHome
      );
    },
  },
  components: {
    CountryModal,
  },
  methods: {
    ...mapActions({
      toggleModal: "countryModal/toggleModal",
      switchLocale: "localization/switchLocale",
    }),
    changeCountry() {
      this.toggleModal(1);
    },
    changeAppLocale(locale) {
      this.switchLocale(locale);
      const query = this.$route.query;
      this.$router.push({
        params: {
          locale,
        },
        query: query,
      });

      //
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    handleScroll() {
      this.isHome = window.scrollY === 0;
    },
  },
  async created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  watch: {
    appLocale(value) {
      this.$i18n.locale = value;
    },
  },
});
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/helpers/_include.scss";

.international-container {
  cursor: pointer;
  text-transform: capitalize;
}

.international, .shipping-to {
  @include font($font-sm - 2);
  color: $secondary2;
  margin: 0;
  font-family: MyriadPro, sans-serif;
  line-height: .95;
}


.world {
  width: 18px;
}

.image-International {
  height: 20px;
  width: 20px;
  margin-bottom: 5px;
}

.countryname {
  display: inline;
  margin-left: 5px;
  text-transform: capitalize;
}

.choose-country p {
  font-family: BigCaslon, sans-serif;
  padding: 10px 0 0 10px;
  font-style: normal;
  color: $secondary2;
  @include font($font-sm);
}

.choose-country .country-text {
  font-family: BigCaslon, sans-serif;
  color: #757575;
  @include font($font-sm);
}

.international-container {
  cursor: pointer;
}

.currency {
  padding: 2.5px 10px;
  border: none;
  //remove border safari , opera , mozilla
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  transition: all 0.3s ease-in-out;
  color: $secondary2;
  @include font($font-sm);

  &:hover {
    border-bottom: 2px solid $secondary1;
  }

  &:focus, &:focus-within, &:active, &:focus-visible {
    outline: none;
    border: none;
  }
}

.home {
  color: $white;
}

.locale {
  @include font($font-sm - 2);
  color: $secondary2;
  font-weight: 600;
  line-height: 20px;
  margin: auto 5px;
  cursor: pointer !important;

  &.home {
    color: $white;
  }
}

i.flag:not(.icon) {
  height: 16px;
}

.pointer {
  cursor: pointer !important;
}
</style>





