import axios from '@/axios';
import categoryInterface from "@/interfaces/category.interface";


export default async function getMainCategories(): Promise<categoryInterface[]> {
    let categories = [];
    const path = `/category/list`
    const response = await axios.get(`${path}`);
    categories = response.data;
    return categories;
}


export async function getAllCategoriesFlat(): Promise<categoryInterface[]> {
    let categories = [];
    const path = `/category`
    const response = await axios.get(`${path}`);
    const array = [];
    const data = response.data;
    for (let i = 0; i < data.length; i++) {
        if (array.length > 0 && !('children' in array[array.length - 1 ])){
            array[array.length - 1 ].children=[]
        }
        if (!data[i].parentId  && data[i].id != 64 && array.length <= 4) {
            array.push(data[i]);
        } else if (data[i].id != 64) {
            if (i > data.length - 5 && i < data.length - 1){
                data[i].main = true
            }
            array[array.length - 1 ].children.push(data[i])
        }
    }
    categories = array;

    return categories;
}

export async function getAllCategories(): Promise<categoryInterface[]> {
    let data = [];
    const path = `/category/all`
    const response = await axios.get(`${path}`);
    data = response.data;
    return data;
}


export async function getChilderenCategories(id: number): Promise<categoryInterface[]> {
    let data = [];
    const path = `/category/list?id=` + id
    const response = await axios.get(`${path}`);
    data = response.data;
    return data;
}
