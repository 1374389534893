import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/header/Down.svg'


const _withScopeId = n => (_pushScopeId("data-v-375ebba8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "nav-list"
}
const _hoisted_2 = { class: "nav-item text-center pointer-cursor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", null, [
    (!_ctx.showSub)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          _createElementVNode("img", {
            class: _normalizeClass([_ctx.local === 'ar' ? 'right-drop' : 'left-drop', "side-back"]),
            alt: "img",
            height: "25",
            width: "25",
            src: _imports_0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeShopping')))
          }, null, 2),
          _createElementVNode("li", _hoisted_2, [
            _createElementVNode("div", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeSide')))
            }, [
              _createVNode(_component_router_link, { to: "/about-us" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("header.aboutus")), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("li", {
            class: "nav-item text-center pointer-cursor",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('closeSide')))
          }, [
            _createElementVNode("div", null, [
              _createVNode(_component_router_link, { to: "/our-history" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("header.ourhistory")), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("li", {
            class: "nav-item text-center pointer-cursor",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('closeSide')))
          }, [
            _createElementVNode("div", null, [
              _createVNode(_component_router_link, { to: "/certificate" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("header.certificate")), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}