import axios from "@/axios";

export default {
  async getWishlistItems(context: any) {
    const path = `users/wishlist/items?limit=10&offset=0`;
    try {
      const wishlistItem = await axios.get(`${path}`);
      return context.commit("SetWishListItem", wishlistItem.data);
    } catch (e: any) {
      console.error(e);
    }
  },

  async addToWishlist(context: any, payload: any) {
    const path = `users/wishlist/products/`;
    try {
      await axios.post(`${path}` + payload.id);
      return context.dispatch("getWishlistItems");
    } catch (e: any) {
      const i = e;
      return e;
    }
  },

  async RemoveFromWishlist(context: any, payload: any) {
    const path = `users/wishlist/`;
    try {
      await axios.delete(`${path}` + payload.id);
      return context.dispatch("getWishlistItems");
    } catch (e: any) {
      const i = e;
      return e;
    }
  },
};
