
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";

export default defineComponent({
  methods: {
    ...mapActions({getCategory: "home/getMainCategory"}),
  },
  computed: {
    ...mapGetters({
      mainCategory: "home/mainCategory",
      locale: "localization/locale",
    }),
    categorys(): any {
      return this.mainCategory;
    },
  },
  async created() {
    if (this.categorys.length === 0) {
      await this.getCategory();
    }
  },
});
