
import {defineComponent} from "vue";
import ShopCategory from "@/components/home/ShopCategory.vue";
import Popular from "@/components/home/Popular.vue";
// import Wonderful from "@/components/home/Wonderful.vue";
import CateringServices from "@/components/home/CateringServices.vue";
import History from "@/components/home/History.vue"
import LuxuryGiftsCollection from "@/components/home/LuxuryGiftsCollection.vue"
import VideoBackground from "@/components/ui/video-player/VideoBackground.vue";
import {mapGetters} from "vuex";

export default defineComponent({
  name: "Home",
  components: {
    VideoBackground,
    ShopCategory,
    Popular,
    CateringServices,
    History,
    LuxuryGiftsCollection,
  },
  computed: {
    ...mapGetters({
      language: "localization/locale",
      main: "zalatimo/getMainData",
      getStorage: "countryModal/getSession",
    })
  },
  created() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
});
