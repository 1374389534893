import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/popular/unlike.svg'
import _imports_1 from '@/assets/images/popular/liked.svg'


const _withScopeId = n => (_pushScopeId("data-v-20c1b36e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "product"
}
const _hoisted_2 = { class: "product-icon_container" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "product-name text-capitalize" }
const _hoisted_7 = { class: "product-price text-uppercase" }
const _hoisted_8 = { class: "mt-3" }
const _hoisted_9 = {
  key: 1,
  class: "new-card"
}
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "new-card-body" }
const _hoisted_12 = { class: "new-card-container" }
const _hoisted_13 = { class: "new-card-name" }
const _hoisted_14 = { class: "product-price text-uppercase" }
const _hoisted_15 = {
  class: "new-card-end",
  role: "button"
}
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDBSpinner = _resolveComponent("MDBSpinner")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_GetQuote = _resolveComponent("GetQuote")!
  const _component_MDBModal = _resolveComponent("MDBModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (!_ctx.isLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_Transition, { name: "fade" }, {
                    default: _withCtx(() => [
                      (!_ctx.fav)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            class: "product-icon",
                            alt: "not favorite",
                            src: _imports_0,
                            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addToFavourite && _ctx.addToFavourite(...args)))
                          }))
                        : (_openBlock(), _createElementBlock("img", {
                            key: 1,
                            class: "product-icon",
                            alt: "favorite",
                            src: _imports_1,
                            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.RemoveFromFav && _ctx.RemoveFromFav(...args)))
                          }))
                    ]),
                    _: 1
                  })
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_MDBSpinner, {
                    grow: "",
                    class: "primary",
                    size: "sm"
                  })
                ]))
          ]),
          _createVNode(_component_router_link, {
            class: "d-block product-link",
            to: { path: _ctx.path }
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: "product-img_container",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.goTo && _ctx.goTo(...args)))
              }, [
                (_ctx.item?.mediaUrl)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "product-img",
                      src: _ctx.item?.mediaUrl ? _ctx.item?.mediaUrl.replace('cdn.', '') : require('@/assets/images/'+_ctx.$myGlobalVariable?.defaultImage),
                      alt: "media"
                    }, null, 8, _hoisted_5))
                  : _createCommentVNode("", true)
              ]),
              _withDirectives(_createElementVNode("div", { class: "name-hint text-capitalize" }, _toDisplayString(_ctx.local === "ar" && _ctx.item?.translations && _ctx.item?.translations?.arabic
              ? _ctx.item?.translations?.arabic
              : _ctx.item?.name), 513), [
                [_vShow, _ctx.item.name.length > 30]
              ]),
              _createElementVNode("p", _hoisted_6, [
                _createElementVNode("bdi", null, _toDisplayString(_ctx.local === "ar" && _ctx.item?.translations && _ctx.item?.translations?.arabic
                ? _ctx.item?.translations?.arabic?.name
                : _ctx.item?.name?.length > 30
                    ? _ctx.item?.name?.slice(0, 30) + "..."
                    : _ctx.item?.name), 1)
              ]),
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.price) + " " + _toDisplayString(_ctx.currency), 1)
            ]),
            _: 1
          }, 8, ["to"]),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.item.canBuy)
              ? (_openBlock(), _createBlock(_component_BaseButton, {
                  key: 0,
                  onClick: _ctx.addToCart
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("home.addtocart")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : (_openBlock(), _createBlock(_component_BaseButton, {
                  key: 1,
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('open', _ctx.item.id)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("getQuote")), 1)
                  ]),
                  _: 1
                }))
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", {
            class: "new-card-image-container",
            role: "button",
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.goTo && _ctx.goTo(...args)))
          }, [
            (_ctx.item?.mediaUrl)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: _normalizeClass(_ctx.category ?'new-card-image-cover' : 'new-card-image' ),
                  src: _ctx.item?.mediaUrl ? _ctx.item?.mediaUrl.replace('cdn.', '') : require('@/assets/images/'+_ctx.$myGlobalVariable?.defaultImage),
                  alt: "media"
                }, null, 10, _hoisted_10))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", {
                class: "new-card-start",
                role: "button",
                onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.goTo && _ctx.goTo(...args)))
              }, [
                _createElementVNode("p", _hoisted_13, [
                  _createElementVNode("bdi", null, _toDisplayString(_ctx.local === "ar" && _ctx.item?.translations && _ctx.item?.translations?.arabic
                    ? _ctx.item?.translations?.arabic?.name
                    : _ctx.item?.name?.length > 30
                        ? _ctx.item?.name?.slice(0, 30) + "..."
                        : _ctx.item?.name), 1)
                ]),
                _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.currency) + " " + _toDisplayString(_ctx.price), 1)
              ]),
              _createElementVNode("div", _hoisted_15, [
                (!_ctx.isLoading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      _createVNode(_Transition, { name: "fade" }, {
                        default: _withCtx(() => [
                          (!_ctx.fav)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                class: "product-icon",
                                alt: "not favorite",
                                src: _imports_0,
                                onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.addToFavourite && _ctx.addToFavourite(...args)))
                              }))
                            : (_openBlock(), _createElementBlock("img", {
                                key: 1,
                                class: "product-icon",
                                alt: "favorite",
                                src: _imports_1,
                                onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.RemoveFromFav && _ctx.RemoveFromFav(...args)))
                              }))
                        ]),
                        _: 1
                      })
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _createVNode(_component_MDBSpinner, {
                        grow: "",
                        class: "primary",
                        size: "sm"
                      })
                    ]))
              ])
            ]),
            _createElementVNode("div", _hoisted_18, [
              (_ctx.canAddToCart)
                ? (_openBlock(), _createBlock(_component_BaseButton, {
                    key: 0,
                    class: "w-100",
                    onClick: _ctx.addToCart
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("home.addtocart")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : (!_ctx.item.canBuy)
                  ? (_openBlock(), _createBlock(_component_BaseButton, {
                      key: 1,
                      class: "w-100",
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('open', _ctx.item.id)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("getQuote")), 1)
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_BaseButton, {
                      key: 2,
                      class: "w-100"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("home.outOfStock")), 1)
                      ]),
                      _: 1
                    }))
            ])
          ])
        ])),
    _createElementVNode("div", null, [
      _createVNode(_component_MDBModal, {
        id: "GetQuote",
        tabindex: "-1",
        labelledby: "GetQuoteLabel",
        modelValue: _ctx.isGetQuote,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.isGetQuote) = $event)),
        centered: "",
        size: "lg"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_GetQuote, {
            onClose: _cache[9] || (_cache[9] = ($event: any) => (_ctx.isGetQuote = false)),
            productId: _ctx.item?.id
          }, null, 8, ["productId"])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ], 64))
}