export default {
  "componeta": {
    
  },
  "orderCon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Order Has Been Confirmed , Check Your Email For More Details"])},
  "goHOme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go To Home Page"])},
  "review": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Write Review"])},
    "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write Your Review Here ..."])}
  },
  "quote": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Item Is Custom Made & Is Produced Upon Your Special Request.<br/> To Receive More Information Regarding This Particular Model & Other Possible Options & Models.<br/> Kindly Leave Your Email Or Your Phone Number And You Will Be Contacted Promptly By One of Our Professionals."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number or Email"])},
    "emailPla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Your Phone Number or Email"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "DescriptionPLa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["..."])},
    "sendQuote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Quote"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Contact Us"])}
  },
  "reset": {
    "newPas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
    "conPas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password "])},
    "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email address."])},
    "enterVEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email address."])},
    "passNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be equal to or more than 8 character"])},
    "resetSuc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Successfully"])},
    "passMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be matched"])},
    "byEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Email"])},
    "otherEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Way"])}
  },
  "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out Of Stock"])},
  "header": {
    "favourite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favourite"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zalatimo Brothers For Sweets"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "shopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
    "internationaldelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International Delivery"])},
    "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CATEGORIES"])},
    "ourStores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Stores"])},
    "gifts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIFTS"])},
    "aboutus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
    "cake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cake"])},
    "mediterraneandesserts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mediterranean Delights"])},
    "chocolates&gifts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chocolates & Gifts"])},
    "specialpackages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Packages"])},
    "traditionalproducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traditional Products"])},
    "ramadansweets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ramadan Sweets"])},
    "giftvouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift Vouchers"])},
    "corporategifts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate Gifts"])},
    "ourhistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our History"])},
    "virtualtour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIRTUAL TOUR"])},
    "catering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1860 Catering"])},
    "gourmet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1860 Gourmet"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awards and Certificate"])},
    "jordan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordan"])},
    "International": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International"])}
  },
  "category": {
    "mediterranean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mediterranean Delights"])},
    "baklava": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baklava Assortment"])},
    "mamoul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maamoul"])},
    "barazek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barazek & Ghriebeh"])},
    "nougat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nougat"])},
    "traditionalTreats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traditional Treats"])},
    "driedFruit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dried Fruit & Dates"])},
    "ramadanSweets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ramadan Sweets"])},
    "cakePatisserie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cakes & Patisserie"])},
    "cake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cakes"])},
    "iceCream": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ice Cream Cakes"])},
    "trifles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trifles"])},
    "customCake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Cakes"])},
    "artisanalDelights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artisanal Delights"])},
    "chocolates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chocolates, Gifts & Special Occasions"])},
    "chocolatesDragees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chocolate Products"])},
    "occasions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Occasions"])},
    "wedding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wedding & Engagement"])},
    "Graduation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduation"])},
    "born": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Born"])},
    "giftVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift Vouchers"])},
    "traditional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traditional Products"])},
    "oil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olive Oil"])},
    "zaatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Made Zaatar & Sumac"])},
    "syrups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syrups"])},
    "honey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honey"])},
    "natural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natural Fragranced Water"])},
    "specialPacking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Packaging"])},
    "emblems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Emblems"])},
    "milestones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Milestones"])},
    "woodenGlass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wooden Glass Boxes"])},
    "knafeh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knafeh - Muttabaq"])},
    "pralines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pralines"])},
    "truffles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truffle"])},
    "dragees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dragee"])},
    "arrangements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrangements"])},
    "chocolateProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chocolate Products"])},
    "sesameTreats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesame Treats"])}
  },
  "home": {
    "popularproduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POPULAR PRODUCT"])},
    "addedtocart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added To Cart"])},
    "addtocart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADD TO CART"])},
    "shopbycatrgory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SHOP BY CATEGORY"])},
    "cateringServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CATERING SERVICES"])},
    "whatWeDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHAT WE DO"])},
    "cateringsDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Expert Team Of Chefs Will Design A Mouthwatering Menu And Provide A Full Service Catering To Your Private Occasions, Our Aim Is To Reflect Your Style, And Your Brand. We Bring Our Flare To All Your Events, Be It Private Or Corporate. With Us Your Event Will Be Unforgettable.\nContact information:"])},
    "contactus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT US"])},
    "luxurygifts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxury Gifts"])},
    "ourhistorydetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It all began in Jerusalem in the year 1860, when our great grandfather Mohammed Zalatimo created a unique kind of sweet he named “Mutabbaq Zalatimo” using only the finest hand- picked ingredients available. Today we the Zalatimo Brothers for Sweets continue our grandfather’s tradition by passionately adding a wide range of specialized Mediterranean delights, Cakes and Chocolate. Our products proudly represent our heritage, quality, passion and perfection, all our products are produced through sourcing the finest ingredients available world wide."])},
    "readmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["READ MORE"])},
    "historydetails1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It all began in Jerusalem in the year 1860, when our great grandfather Mohammed Zalatimo created a unique kind of sweet he named “Mutabbaq Zalatimo” using only the finest hand- picked ingredients available."])},
    "historydetails2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today we the Zalatimo Brothers for Sweets continue our grandfather’s tradition by passionately adding a wide range of specialized Mediterranean delights, Cakes and Chocolate."])},
    "historydetails3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our products proudly represent our heritage, quality, passion and perfection, all our products are produced through sourcing the finest ingredients available world wide."])},
    "ourhistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR HISTORY"])},
    "relatedProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related Products"])},
    "outOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out Of Stock"])},
    "shopNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SHOP NOW"])}
  },
  "contactUsPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening Hours"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tel"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
    "tour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take a tour"])},
    "addressInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al Shareef Abed Al Hameed Sharaf - Shmeisani, Amman, Jordan"])},
    "hoursInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From Saturday To Friday, 10:00 am - 10:00 pm"])},
    "hoursInfoAirport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airport Branch 24h"])}
  },
  "Wishlist": {
    "titlewishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WishList"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Wishlist is empty"])}
  },
  "stores": {
    "weekdays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekdays"])},
    "weekends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekends"])},
    "viewonmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View On Map"])}
  },
  "footer": {
    "oursocial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR SOCIAL NETWORKS "])},
    "securePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECURED PAYMENTS"])},
    "right": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Copyright © ", _interpolate(_named("date")), ". All rights reserved."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["footer en title"])},
    "terms&conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Conditions"])},
    "allRightsReserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Zalatimo Brothers for Sweets, All Rights Reserved"])},
    "cake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cakes"])},
    "delights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mediterranean Delights"])},
    "sweets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chocolates, Gifts & Special Occasions"])},
    "knafeh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knafeh - Muttabaq"])},
    "poweredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered By"])},
    "level2": {
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lvl 2 data"])}
    },
    "awardsAndCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awards and Certificate"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
    "Knafeh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knafeh"])},
    "Occasions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occasions"])},
    "boy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boy"])},
    "girl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Girl"])},
    "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
    "virtualtour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual Tour"])},
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow Us"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shops"])},
    "shops": {
      "Shmeisani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shmeisani"])},
      "AlMadina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al Madina Al Munawara"])},
      "Swefieh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swefieh"])},
      "Abdulla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abdulla Ghosheh St."])},
      "Dabouq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dabouq"])},
      "Dubai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dubai"])},
      "AirportRoad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airport Road - Jammous Plaza"])},
      "AirportCentral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airport Central"])},
      "AirportDeparture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airport Departure"])},
      "AirportNorth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airport North"])},
      "AirportSouth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airport South"])},
      "Abdali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abdali Boulevard"])}
    }
  },
  "aboutUs": {
    "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
    "historyOrigin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History and Origin"])},
    "historyOriginMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our story began in a divine city that shines beauty and ancient history upon the whole world, Jerusalem. Inspired by the unmtached cultural heritage of the city, our great grandfather, Mohammad Zalatimo, established the first ‘Zalatimo’ shop for middleastern sweets in the heart of Jerusalem back in the year 1860. Soon the shop became popular for a certain type of sweets called ‘Muttabaq” that he himself created. It became the habit of the people of Jerusalem to have this sweet (paper thin folder filo dough filled with metled white local cheese) for breakfast. By time, other types of mouthwatering sweets began to be sold in this shop, paving the way for a great true and authentic Middleastern swwets brand to be born."])},
    "ourStory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Story"])},
    "artSweetMaking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Art of Sweet Making"])},
    "artSweetMakingMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today, The Zalatimo brothers, Mr. Ahmed Wael Zalatimo and Mr. Talal Wael Zalatimo, take it upon themselves to keep the business of their grandfather and his passion for sweets alive. Together They established Zalatimo Brothers for Sweets and kept serving the original recipe of Muttabaq alongside a huge variety of both eastern as well as western sweets and pastry. With pride, they offer a large assortment of gourmet sweets and carry with dedication the tradition of their ancestors for generations to come. With many branches all over Jordan and a state-of-the-art factory, their beloved business grew and nurtured depending on the finest ingredients and utmost quality. Fresh gourmet sweets are guaranteed in each piece. Made with passion, Zalatimo Brothers for Sweets give sheer pleasure with each bite."])},
    "artSweetMakingMsg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The story of authenticity goes on as we keep innovating new types of sweets that please the eye before the sense of taste. Wherever they are, sweet lovers can enjoy our products by ordering online and they will surely enjoy their freshness with our carefully packaged boxes that savor the aromatic flavors. Our own original recipes made with the best ingredients available worldwide distinguish us. These fresh ingredients are delicately put together with the hands of highly trained and dedicated chefs. With the help of some of the most modern sweets manufacturing machinery, we brought the making of Middleastern Delights to a new level. To encourage their creativity and ensure their loyalty, we provide our employees with a very relaxing and productive work environment.\n \nOur main products include Middleastern sweets, cakes, chocolate (including special arrangements and baskets), nougat, icecream, traditional products ( cold pressed olive oil, honey, homemade jams etc.) and products for special occasions such as new borns, weddings and engagements, birthdays, and graduation.  Some of our renowned Middleastern delights include Baklawa, Maamoul (filled with dates, pistachios or walnuts), Ghriebeh, Noughat, Turkish Delights, Dried Fruits, amongst tens of other delicious traditional desserts. "])}
  },
  "ourHistory": {
    "ourHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our History"])},
    "header1860": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Mediterranean Delights Shop Established In 1860"])},
    "text1860": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The “Zalatimo Brothers for Sweets” story began in the divine city that shines upon the world, Jerusalem. Inspired by its cultural heritage, Mr. Mohammad Zalatimo established the first shop for mediterranean sweets in the heart of Jerusalem in the year 1860."])},
    "header1890": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Shop Became Famous For “Muttabaq” In 1890"])},
    "text1890": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A type of sweet of his creation. Made of paper-thin folded filo dough and filled with a special local white cheese. Soon after, “Muttabaq” became a popular breakfast locally."])},
    "header1996": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zalatimo Brothers For Sweets Opened In Jordan In 1996"])},
    "text1996": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With pride, Zalatimo Brothers for Sweets carry the tradition of their great grandfather giving their utmost attention to quality & continuous creativity & innovation."])}
  },
  "cateringServices": {
    "cateringServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catering Services"])},
    "cateringServicesMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Expert Team Of Chefs Will Design A Mouthwatering Menu And Provide A Full Service Catering To Your Private Occasions, Our Aim Is To Reflect Your Style, And Your Brand. We Bring Our Flare To All Your Events, Be It Private Or Corporate. With Us Your Event Will Be Unforgettable."])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information:"])}
  },
  "location": {
    "subTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Total"])},
    "myOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Order Information"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["price"])},
    "shippingCost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping"])},
    "deliveryCost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net"])},
    "discountAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
    "useCoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Order"])},
    "selectAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Your Address To Confirm Your Order"])},
    "changeAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Address"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Address"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items In Your Cart."])},
    "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])},
    "noteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes Is Required"])},
    "couponIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon is"])},
    "couponCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon Code"])},
    "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid From"])},
    "validUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired At"])},
    "currentAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Amount"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Address"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose From My Address"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "addError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Make Sure That All Fields Is Field"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])}
  },
  "cart": {
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Total"])},
    "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, Your Cart is Empty"])},
    "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add something to your cart."])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Shopping"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Price"])},
    "totalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Price"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Cart"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "allInclusive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices Are All Inclusive Of Worldwide Shipping Cost"])},
    "dhlNotes2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disclaimer: All Products Are Shipped Via DHL Express or Aramex, When Placing Your\nOrder, You Agree To Engage With DHL or Aramex, Or A Local Broker Hired By DHL or\nAramex To Deliver Your Products. You Acknowledge And Agree That DHL or Aramex May\nCharge You A Brokerage Fee In Addition To Any Applicable Duties Or Taxes, Which May Be\nCalculated As Either A Percentage Of The Value Of The Shipment Or A Minimum Fee. DHL\nOr Aramex May Either Require This Fee Upfront When You Sign For The Goods, Or they\nMay Invoice You By Mail. We Receive No Part Of These Fees And Cannot Provide A\nRefund For Any Such Costs Incurred. It Is Your Responsibility to Ensure That the Delivery\nDetails You Provided Are Accurate and Complete. Otherwise, We Will Not Be Able to Deliver\nthe Products You Have Purchased."])},
    "dhlNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that the final price inclusive of the shipping cost will be calculated at checkout depending on the destination."])}
  },
  "productCard": {
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Details"])}
  },
  "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT US"])},
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My orders"])},
  "myAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Account"])},
  "terms": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
    "agreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and any or all Agreements:\n"])},
    "agreementBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client, “You” and “Your” refers to you, the person accessing this website and accepting the Company’s terms and conditions. \"The Company\", “Ourselves”, “We” and \"Us\", refers to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves, or either the Client or ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner, whether by formal meetings of a fixed duration, or any other means, for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services/products, in accordance with and subject to, prevailing [Country Law]. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same."])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Statement"])},
    "privacyBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are committed to protecting your privacy. Authorized employees within the company on a need to know basis only use any information collected from individual customers. We constantly review our systems and data to ensure the best possible service to our customers.\n"])},
    "cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation Policy:"])},
    "cancellationBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if the order is canceled within 24 hrs from placing it. A full money refund will be granted .After the first 24 hrs the order will be processed and considered final ."])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy:"])},
    "stateBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zalatimo Brothers for Sweets guarantee that all information regarding its customers will handled with full privacy .\n"])},
    "refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund Policy:"])},
    "refundBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zalatimo Brothers for sweets guarantee full products and customer satisfaction. In the case of any complaint regarding either product or service a full refund will be issued to the customer."])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Policy:"])},
    "shippingBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Products Are Shipped Via DHL Express or Aramex, When Placing Your\nOrder, You Agree To Engage With DHL or Aramex, Or A Local Broker Hired By DHL or\nAramex To Deliver Your Products. You Acknowledge And Agree That DHL or Aramex May\nCharge You A Brokerage Fee In Addition To Any Applicable Duties Or Taxes, Which May Be\nCalculated As Either A Percentage Of The Value Of The Shipment Or A Minimum Fee. DHL\nOr Aramex May Either Require This Fee Upfront When You Sign For The Goods, Or they\nMay Invoice You By Mail. We Receive No Part Of These Fees And Cannot Provide A\nRefund For Any Such Costs Incurred. It Is Your Responsibility to Ensure That the Delivery\nDetails You Provided Are Accurate and Complete. Otherwise, We Will Not Be Able to Deliver\nthe Products You Have Purchased."])},
    "international": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International Order: 4 to 6 working days."])},
    "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local Order: 1 to 2 working days."])}
  },
  "store": {
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delivery number"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "ourStores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Stores"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "openingHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening Hours"])}
  },
  "address": {
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new Address"])},
    "addressLine1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Line 1"])},
    "addressLine2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Line 2"])},
    "consigneeCountryCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consignee Country Code"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Name"])},
    "countryCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Code"])},
    "countryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Name"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "isDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Default"])},
    "latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latitude"])},
    "longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longitude"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
    "stateOrProvince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State Or Province"])},
    "taxId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["taxID"])},
    "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update dAt"])},
    "userId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User ID"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Details"])},
    "updateAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Address"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "errorConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Select Address To Create Your Order"])},
    "errorConfirmCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Cart Is Empty"])}
  },
  "luxury": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxury Gifts"])},
  "modal": {
    "shippingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your shipping destination"])},
    "jordan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping to Jordan"])},
    "international": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Worldwide"])},
    "shippingTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure?"])},
    "sureText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices Are All Inclusive Of Worldwide Shipping Cost. The items that cannot be shipped over seas will be removed from your cart"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
  },
  "product": {
    "additionalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Information"])},
    "inside": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What’s Inside?"])},
    "netWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Weight"])},
    "packageDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Depth"])},
    "packageHeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Height"])},
    "packageWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Width"])},
    "packageWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Weight"])},
    "title": {
      "searchedProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searched : "])},
      "filteredProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtered Product"])},
      "allProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" All Product"])}
    },
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantity"])},
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Results Found"])}
  },
  "myAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Address"])},
  "submit": {
    "creditCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Card"])}
  },
  "profile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile"])},
    "MyAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Account"])},
    "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "PhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "MyOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Orders"])},
    "MyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Address"])},
    "addAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New address"])},
    "noOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no previous requests"])},
    "noAddre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no previous Address"])},
    "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
    "taxId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax ID"])},
    "ConsigneeCountryCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consignee Country Code"])},
    "phoneNUmber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "address1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Line1"])},
    "Address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Line2"])},
    "PostalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
    "selectMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or Select Location On Map"])},
    "confirmLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Location"])},
    "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Profile"])},
    "updateInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Your Info"])},
    "QRCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount QR Code"])},
    "generateQRCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate QR Code"])},
    "getQRCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get QR Code"])},
    "QRCodeNotAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, you don't have a discount."])},
    "QRCodeAvailable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have a ", _interpolate(_named("value")), " JOD discount."])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])}
  },
  "priceFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "priceFilterUnder20": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["20 ", _interpolate(_named("currency")), "s and under"])},
  "priceFilter50": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["20 ", _interpolate(_named("currency")), "s - 50 ", _interpolate(_named("currency")), "s"])},
  "priceFilter50-100": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["50 ", _interpolate(_named("currency")), "s - 100 ", _interpolate(_named("currency")), "s"])},
  "priceFilter100up": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["100 ", _interpolate(_named("currency")), " and up"])},
  "categoryFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "FilterData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter Product"])},
  "myOrders": {
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
  },
  "order": {
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Price"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Cost"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "varchar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varchar"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "orderno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order No. "])},
    "downloadTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download tax invoice"])},
    "OrderPlaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Placed"])},
    "OrderPacked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Packed"])},
    "OutOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out for Shipping"])},
    "orderRec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Received"])},
    "track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Track Order"])},
    "reportPr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report a problem"])},
    "problemCont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Zalatimo’s customer support will contact you later on"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "dhlNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  DHL Order Number"])},
    "ShipmentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment Date"])},
    "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Other (specify)"])}
  },
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "createAcco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an Account"])},
  "signUp": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to receive marketing messages from Zalatimo brothers."])},
    "Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking register, you consent to our"])},
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms & conditions"])},
    "haveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Already have an account?"])},
    "loginhere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login here"])},
    "fname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "lname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your phone number"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "OR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OR"])},
    "welcomeB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome Back"])},
    "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Forgot password?"])},
    "dontHave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Don’t have an account?"])},
    "Registerhere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register here"])},
    "buyAsGuest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy As Guest"])}
  },
  "certificate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate"])},
    "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The sensational taste of our sweets is first and foremost recognized by our customers alongside we achieved a bouquet of official Certificates & Awards. Most importantly is the Jordan First King's award.\n\n"])}
  },
  "report": {
    "error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Did’t Receive My Order"])},
    "error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product is damaged"])},
    "error3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not What I Ordered"])},
    "error4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something Is Wrong With My Order"])}
  },
  "checkout": {
    "confirmOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Your Order"])},
    "youHave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Have   "])},
    "InCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" In Your Cart"])},
    "Items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items"])},
    "Item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item"])},
    "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "anyAdditionalNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any Additional Notes"])},
    "ShippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Address"])},
    "ReviewItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review Items"])},
    "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do You Have A Coupon Code / Gift Voucher?"])},
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check"])},
    "couponNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon not found"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Do you have notes or requests?"])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(optional)"])},
    "AddNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Notes"])},
    "ApplyNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Now"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "PaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method"])},
    "usePay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use Address Details As Billing Address"])},
    "writeNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write Your Note Here ..."])}
  },
  "myPassword": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Your Password"])},
    "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email address and we will send you  a link to change your password."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "ResetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])}
  },
  "price": {
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min Price"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Price"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter Price"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid price. the minimum price should be less than the maximum price."])}
  },
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "corporateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate"])},
  "corporate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a premium name in the sweets business in Jordan, “Zalatimo Brothers” has paid special attention to the image of its corporate identity. Design and branding activities are consistent with a clear presence of refined and creative designs to match the traits of our products. We offer our special customers the luxury to customize their own distinct packages; of their occasion. Hence, all customized giveaways and packaging are branded based on your needs.\n\n"])},
  "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry. This Product Not Found"])},
  "addressNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address you chosen is deleted. Please choose new address to complete your order"])},
  "internationalNoteForInclusive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Details:<br/>Prices Are All Inclusive Of Worldwide Shipping Cost.<br/>Estimated Delivery Is 3-5 Working Days."])},
  "thanksForYourOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your order!"])},
  "emailSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have sent you an email with your order details and receipt. If the email does not appear in your inbox, please check your spam folder."])},
  "goToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Home"])},
  "getQuote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Quote"])},
  "cash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "cartHintInJordan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All orders placed before 3:00 pm will be delivered on the same day of ordering."])},
  "cartHintInJordanAfter3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='text-danger'> The orders placed after 3:00 pm, delivery will be scheduled for next day </span> <b> unless </b> the products and the delivery service are available, these orders will be delivered on the same day."])}
}