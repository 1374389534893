
import {defineComponent} from "vue";
import {mapGetters, mapActions} from "vuex";
import CurrencyDesktop from "@/components/navigation/CurrencyDesktop.vue";

export default defineComponent({
  name: "ServesIconDesktop",
  emits: ["openSearch"],
  components: {
    CurrencyDesktop,
  },

  data() {
    return {
      inputssearch: false,
      authDropDown: false,
      q: "",
      isHome: true,
    };
  },

  computed: {
    ...mapGetters({
      authenticated: "auth/isAuthenticated",
      local: "localization/locale",
    }),
    ...mapGetters({
      cart: "cart/cartItemsNumber",
    }),
    ...mapGetters({
      appLocale: "localization/locale",
    }),
    checkProfile() {
      const fullPath = this.$route.fullPath;
      return fullPath.indexOf("profile") > -1;
    },
    checkWishlist() {
      const fullPath = this.$route.fullPath;
      return fullPath.indexOf("wishlist") > -1;
    },
    checkCart() {
      const fullPath = this.$route.fullPath;
      return fullPath.indexOf("cart") > -1;
    },
    home() {
      return this.$route.name === "HomePage" ||
      this.$route.name === "Home" ||
      this.$route.name === "CateringServices"
          ? false
          : false;
    },
  },

  methods: {
    ...mapActions({
      toggleLoginModal: "auth/toggleLoginModal",
      toggleSignupModal: "auth/toggleSignupModal",
      logout: "auth/logout",
      getItems: "cart/getCartItems",
    }),
    openSearch() {
      this.inputssearch = !this.inputssearch;
      this.$emit("openSearch");
    },
    login() {
      this.authDropDown = false;
      this.toggleLoginModal(true);
    },

    signup() {
      this.authDropDown = false;
      this.toggleSignupModal(true);
    },

    signOut() {
      this.authDropDown = false;
      this.$router.push("/" + this.appLocale)
      this.logout();

    },

    search() {
      let key = this.q;
      this.q = "";
      this.$router.push({path: "/" + this.local + "/all-products", query: {q: key}});
    },
    handleScroll() {
      if (window.scrollY === 0) {
        this.isHome = false;
      } else {
        this.isHome = false;
      }
    },
  },

  async created() {
    await this.getItems();
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
});
