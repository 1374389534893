
import { defineComponent } from "vue";
import SlideBar from "../common/SlideBar.vue";
import ProductInterface from "@/interfaces/product.interface";
import getProducts, { type } from "@/services/product.service";

export default defineComponent({
  name: "Popular",
  components: {
    SlideBar,
  },
  data() {
    let params: type = {
      limit: 8,
      offset: 0,
      categoriesId: [64],
      sort: "ALL",
      maxPrice: 10000000,
      minPrice: 0,
      search: null,
      modes: "BY_CATEGORY",
      page: null,
      pageSize: 0,
      productId: null,
    };
    let products: ProductInterface[] | null = [];
    return {
      items: [],
      v: 0,
      params,
      currency: "JOD",
      size: 4,
      products,
      mainTitle: this.$t("luxury"),
    };
  },

  async created() {
    const data = async () => {
      let response = await getProducts(this.params);

      this.products = response.data;
      this.currency = response.currency;

      return response;
    };

    await data();
  },
});
